import { FaInfoCircle } from "react-icons/fa";
import React from "react";
import { clsx } from "clsx";

const InfoTooltip = ({ title, text, theme }) => {
  return (
    <section className="relative inline-block group cursor-pointer z-[1]">
      <div className="center">
        <div
          className={clsx(
            "animate-ping absolute border  rounded-full h-4 w-4",
            theme === "dark" ? "border-gray-300" : "border-gray-700",
          )}
        />
        <FaInfoCircle
          className={clsx(
            "text-xl cursor-pointer ",
            theme === "dark" ? "text-gray-300" : "text-gray-700",
          )}
        />
      </div>

      {/* Tooltip */}
      <div className="absolute right-5 top-8 bg-black bg-opacity-90 text-white text-sm rounded-lg p-3 w-72 shadow-lg invisible group-hover:visible opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-50 pointer-events-none">
        <h3 className="font-bold mb-1">{title}</h3>
        <p>{text}</p>
      </div>
    </section>
  );
};

export default InfoTooltip;
