// import React, { useState, useEffect } from "react";
// import { Select, SelectItem, Button } from "@heroui/react";
// import { clsx } from "clsx";
//
// const AchievementFilters = ({
//   achievements,
//   onFilter,
//   initialCategory,
//   initialRegion,
//   theme,
// }) => {
//   const [categoryFilter, setCategoryFilter] = useState(initialCategory || "");
//   const [regionFilter, setRegionFilter] = useState(initialRegion || "");
//
//   const categories = [
//     "All Categories",
//     ...new Set(achievements.map((a) => a.CATEGORY)),
//   ];
//   const regions = [
//     "All Regions",
//     ...new Set(achievements.map((a) => a.REGION)),
//   ];
//
//   const handleApplyFilters = () => {
//     onFilter({ category: categoryFilter, region: regionFilter });
//   };
//
//   return (
//     <div className="flex w-[35%] gap-5 items-center justify-center">
//       <Select
//         label="Category"
//         placeholder="Filter by category"
//         value={categoryFilter}
//         onChange={(e) => setCategoryFilter(e.target.value)}
//         className={theme === "dark" ? " text-white" : " text-gray-800"}
//       >
//         {categories.map((category) => (
//           <SelectItem key={category} value={category}>
//             {category}
//           </SelectItem>
//         ))}
//       </Select>
//       <Select
//         label="Region"
//         placeholder="Filter by region"
//         value={regionFilter}
//         onChange={(e) => setRegionFilter(e.target.value)}
//         className={theme === "dark" ? " text-white" : " text-gray-800"}
//       >
//         {regions.map((region) => (
//           <SelectItem key={region} value={region}>
//             {region}
//           </SelectItem>
//         ))}
//       </Select>
//       <Button
//         color="primary"
//         onClick={handleApplyFilters}
//         className={clsx(
//           theme === "dark"
//             ? "bg-blue-600 hover:bg-blue-700"
//             : "bg-blue-500 hover:bg-blue-600",
//           "px-5 py-2",
//         )}
//       >
//         Ok
//       </Button>
//     </div>
//   );
// };
//
// export default AchievementFilters;
import React, { useState, useEffect } from "react";
import { Select, SelectItem } from "@heroui/react";
import { clsx } from "clsx";

const AchievementFilters = ({
  achievements,
  onFilter,
  initialCategory,
  initialRegion,
  theme,
}) => {
  const [categoryFilter, setCategoryFilter] = useState(initialCategory || "");
  const [regionFilter, setRegionFilter] = useState(initialRegion || "");

  const categories = [
    "All Categories",
    ...new Set(achievements.map((a) => a.CATEGORY)),
  ];
  const regions = [
    "All Regions",
    ...new Set(achievements.map((a) => a.REGION)),
  ];

  // Update filters and trigger onFilter whenever a filter changes
  const handleCategoryChange = (value) => {
    setCategoryFilter(value);
    onFilter({ category: value, region: regionFilter });
  };

  const handleRegionChange = (value) => {
    setRegionFilter(value);
    onFilter({ category: categoryFilter, region: value });
  };

  return (
    <div className="flex w-[35%] gap-5 items-center justify-center">
      <Select
        label="Categorie"
        placeholder="Filtrer par catégorie"
        value={categoryFilter}
        onChange={(e) => handleCategoryChange(e.target.value)}
        className={theme === "dark" ? "text-white" : "text-gray-800"}
      >
        {categories.map((category) => (
          <SelectItem key={category} value={category}>
            {category}
          </SelectItem>
        ))}
      </Select>
      <Select
        label="Region"
        placeholder="Filtrer par region"
        value={regionFilter}
        onChange={(e) => handleRegionChange(e.target.value)}
        className={theme === "dark" ? "text-white" : "text-gray-800"}
      >
        {regions.map((region) => (
          <SelectItem key={region} value={region}>
            {region}
          </SelectItem>
        ))}
      </Select>
    </div>
  );
};

export default AchievementFilters;
