export const createGridLines = (step, max) => {
  const lines = [];
  for (let i = -max; i <= max; i += step) {
    lines.push([
      [-max, i],
      [max, i],
    ]);
  }

  // Lignes horizontales
  for (let i = -max; i <= max; i += step) {
    lines.push([
      [i, -max],
      [i, max],
    ]);
  }

  return lines;
};

export const convertCoordinates = (x, z) => {
  return [-z, x];
};

export const reverseConvertCoordinates = (lat, lng) => {
  return {
    x: lng,
    z: -lat,
  };
};
export const convertMarkers = (markers) => {
  return markers.map((marker) => ({
    position: convertCoordinates(marker.x, marker.z),
    text: marker.text,
  }));
};

export default {
  createGridLines,
  convertCoordinates,
  reverseConvertCoordinates,
  convertMarkers,
};
