import React, { useEffect, useState } from "react";
import Logo from "../../assets/logos/Logo_EL_Plan_de_travail_1_copie_4.png";
import { FaUser } from "react-icons/fa";
import { FaLock } from "react-icons/fa";
import Button from "../../components/Button/Button";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { login } from "../../services/services.js";
import getMessageMapping from "../../utils/Message.js";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setId } from "../../store/slice";
import { saveToLocalStorage } from "../../utils/utils";
import { isAuthenticate } from "../../services/authContext";

const SignIn = () => {
  useEffect(() => {
    (async () => {
      try {
        const auth = await isAuthenticate();
        if (auth.valid) {
          navigate("/account");
        }
      } catch (e) {
        toast.error(getMessageMapping("USER_DISCONNECT"));
        return;
      }
    })();
  }, []);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    username: null,
    password: null,
  });

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    let valid = true;
    let errors = {
      username: null,
      password: null,
    };

    if (!formData.username) {
      valid = false;
      errors.username = "Le nom d'utilisateur est requis.";
    }
    if (!formData.password || formData.password.length < 8) {
      valid = false;
      errors.password = "Le mot de passe doit contenir au moins 8 caractères.";
    }

    setErrors(errors);

    return valid;
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      setLoading(true);
      try {
        const response = await login({
          username: formData.username,
          password: formData.password,
        });

        let status = response.response.status;

        if (status === 200) {
          saveToLocalStorage(
            "id",
            JSON.stringify(response.response.data.userId),
            true,
          );
          toast.success(getMessageMapping("LOGIN_CORRECT"));
          navigate("/account/");
        }
      } catch (e) {
        if (e.response.data.code === "INCORRECT_PASSWORD") {
          setErrors({
            username: null,
            password: getMessageMapping("INCORRECT_PASSWORD"),
          });
        } else if (e.response.data.code === "USER_NOT_FOUND") {
          setErrors({
            username: getMessageMapping("USER_NOT_FOUND"),
            password: null,
          });
        }
        toast.error("Erreur pendant la connexion");
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="w-full h-[100vh] bgimg center">
      <section className="w-[55vh] h-2/3  bg-white bg-opacity-80  rounded-xl center flex-col relative shadowCustom gap-3">
        <a
          href="https://elementary-land.fr"
          target="_blank"
          rel="noopener noreferrer"
          className="absolute top-5 left-5 bg-red-500 text-white p-1.5 rounded-md font-medium"
        >
          Site ElementaryLand
        </a>
        <img className="w-[150px]" src={Logo} alt={"logo ElementaryLand"} />
        <h1 className="text-center font-bold text-lg ">Panel Administratif</h1>
        <form className="w-full h-40 center flex-col gap-2 mt-5">
          <div
            className={`bg-[#fff] center gap-1 shadowCustom p-2 rounded-md ${errors.username ? "border-red-500" : ""}`}
          >
            <FaUser className="text-lg text-neutral-700" />
            <input
              type="text"
              name="username"
              placeholder="Nom d'utilisateur"
              value={formData.username}
              onChange={handleChange}
            />
          </div>
          {errors.username && (
            <p className="text-red-700 text-sm font-semibold">
              {errors.username}
            </p>
          )}

          <div
            className={`bg-[#fff] center gap-1 shadowCustom p-2 rounded-md ${errors.password ? "border-red-500" : ""}`}
          >
            <FaLock className="text-lg text-neutral-700" />
            <input
              type="password"
              name="password"
              placeholder="Mot de passe"
              value={formData.password}
              onChange={handleChange}
            />
          </div>
          {errors.password && (
            <p className="text-red-500 text-sm font-semibold">
              {errors.password}
            </p>
          )}

          <Button
            type="button"
            onClick={handleSubmit}
            initialIsPending={loading}
            size="medium"
          >
            Connectez Vous
          </Button>
        </form>
      </section>
    </div>
  );
};

export default SignIn;
