import React from "react";

import Containers from "../../components/Container/Container";
import { FaTools } from "react-icons/fa";

const BuildingComponent = () => {
  return (
    <div className="h-[70vh] bg-gray-100 flex items-center justify-center p-4">
      <div className="text-center bg-white shadow-lg rounded-lg p-8 max-w-md w-full">
        <div className="flex justify-center items-center justify-center mb-6">
          <FaTools className="text-6xl text-yellow-500 mr-4" />
        </div>
        <h2 className="text-3xl font-bold text-gray-800 mb-4">
          Composant en Construction
        </h2>
        <p className="text-gray-600 mb-6">
          Le composant est actuellement en développement.
          <br /> Il sera bientôt disponible.
        </p>
      </div>
    </div>
  );
};

const WebDashboard = () => {
  const tabs = [
    {
      name: <span className="flex items-center gap-2">Utilisateurs</span>,
      content: <BuildingComponent />,
      permission: "STAFF",
    },
    {
      name: (
        <span className="flex items-center gap-2">Crée un Utilisateur</span>
      ),
      content: <BuildingComponent />,
      permission: "STAFF",
    },
    {
      name: <span className="flex items-center gap-2">Gestion galerie</span>,
      content: <BuildingComponent />,
      permission: "STAFF",
    },
    {
      name: (
        <span className="flex items-center gap-2">Crée carte attraction</span>
      ),
      content: <BuildingComponent />,
      permission: "STAFF",
    },
  ];
  return <Containers tabs={tabs} title="Dashboard" />;
};

export default WebDashboard;
