import React, { useEffect, useRef, useState } from "react";
import { useButton } from "@react-aria/button";
import { clsx } from "clsx";

import { Link } from "react-router-dom";
import LoaderComponent from "../Loader/Loader";

/**
 * `Buttons` est un composant de bouton personnalisable avec support pour des états de chargement et des icônes.
 *
 * @param {Object} props - Les propriétés du composant.
 * @param {React.ReactNode} props.children - Le contenu à afficher à l'intérieur du bouton.
 * @param {string} [props.className] - Classes CSS supplémentaires à appliquer au bouton.
 * @param {boolean} [props.initialIsPending=false] - Indique si le bouton est en état de chargement au démarrage.
 * @param {boolean} [props.disabled=false] - Indique si le bouton est désactivé.
 * @param {string} [props.type="button"] - Le type du bouton (ex. "button", "submit", "link").
 * @param {boolean} [props.withIcons=false] - Indique si des icônes doivent être affichées dans le bouton.
 * @param {string} [props.iconsPosition="left"] - La position des icônes ("left" ou "right").
 * @param {React.ReactNode} [props.icon] - L'icône à afficher dans le bouton.
 * @param {Function} [props.onClick] - Fonction à appeler lors du clic sur le bouton.
 * @param {string} [props.variant="classic"] - Variante du style du bouton ("minimal_outline", "light", "dark", "primary", "secondary", "danger", "success", "warning", "info", "outline", "minimal", "gradient", "neon", "retro", "pastel", "elevated", "flat")
 * @param {string} [props.size="medium"] - Taille du bouton ("sm", "medium (default)", "large", "xl").
 * @param {string} [props.href] - Lien de destination si `type="link"`.
 *
 * @returns {React.Element} Le composant de bouton ou de lien.
 */
const Buttons = ({
  children,
  className,
  initialIsPending = false,
  disabled = false,
  type = "button",
  withIcons = false,
  iconsPosition = "left",
  icon,
  onClick,
  variant = "classic",
  size = "medium",
  href,
}) => {
  let variantStyles;
  let sizeStyle;

  const [isPending, setIsPending] = useState(initialIsPending);
  const ref = useRef();
  const { buttonProps } = useButton(
    {
      onPress: onClick,
      isDisabled: disabled || isPending,
      type,
    },
    ref,
  );

  useEffect(() => {
    setIsPending(initialIsPending);
  }, [initialIsPending]);

  switch (variant) {
    case "light": {
      variantStyles =
        "hover:bg-neutral-400 transition-bg duration-500 bg-white text-black text-lg font-medium center p-1 rounded-md shadowCustom";
      break;
    }

    case "dark": {
      variantStyles =
        "hover:bg-neutral-700 transition-bg duration-500 bg-gray-900 text-white text-lg font-medium center p-1 rounded-md shadowCustom";
      break;
    }

    case "primary": {
      variantStyles =
        "hover:bg-blue-600 transition-bg duration-500 bg-blue-500 text-white text-lg font-medium center p-1 rounded-md shadowCustom";
      break;
    }

    case "secondary": {
      variantStyles =
        "hover:bg-green-600 transition-bg duration-500 bg-green-500 text-white text-lg font-medium center p-1 rounded-md shadowCustom";
      break;
    }

    case "danger": {
      variantStyles =
        "hover:bg-red-600 transition-bg duration-500 bg-red-500 text-white text-lg font-medium center p-1 rounded-md shadowCustom";
      break;
    }

    case "success": {
      variantStyles =
        "hover:bg-teal-600 transition-bg duration-500 bg-teal-500 text-white text-lg font-medium center p-1 rounded-md shadowCustom";
      break;
    }

    case "warning": {
      variantStyles =
        "hover:bg-yellow-600 transition-bg duration-500 bg-yellow-500 text-black text-lg font-medium center p-1 rounded-md shadowCustom";
      break;
    }

    case "info": {
      variantStyles =
        "hover:bg-cyan-600 transition-bg duration-500 bg-cyan-500 text-white text-lg font-medium center p-1 rounded-md shadowCustom";
      break;
    }

    case "outline": {
      variantStyles =
        "hover:bg-neutral-200 transition-bg duration-500 border border-gray-400 text-gray-800 text-lg font-medium center p-1 rounded-md shadowCustom";
      break;
    }

    case "minimal": {
      variantStyles =
        "hover:bg-transparent transition-bg duration-500 bg-transparent text-gray-600 text-lg font-medium center p-1 rounded-md shadowCustom";
      break;
    }

    case "minimal_outline": {
      variantStyles =
        "hover:bg-neutral-300 transition-bg duration-500 border border-gray-400 text-gray-700 text-lg font-medium center p-1 rounded-md shadowCustom";
      break;
    }
    case "gradient": {
      variantStyles =
        "hover:bg-gradient-to-r hover:from-purple-500 hover:to-blue-500 transition-bg duration-500 bg-gradient-to-r from-pink-500 to-red-500 text-white text-lg font-medium center p-1 rounded-md shadowCustom";
      break;
    }

    case "neon": {
      variantStyles =
        "hover:bg-purple-600 transition-bg duration-500 bg-purple-500 text-white text-lg font-medium center p-1 rounded-md shadow-md shadow-neon";
      break;
    }

    case "retro": {
      variantStyles =
        "hover:bg-orange-600 transition-bg duration-500 bg-orange-500 text-white text-lg font-medium center p-1 rounded-md shadowCustom";
      break;
    }

    case "pastel": {
      variantStyles =
        "hover:bg-pink-200 transition-bg duration-500 bg-pink-100 text-gray-700 text-lg font-medium center p-1 rounded-md shadowCustom";
      break;
    }

    case "elevated": {
      variantStyles =
        "hover:bg-gray-300 transition-bg duration-500 bg-gray-200 text-black text-lg font-medium center p-1 rounded-md shadow-lg";
      break;
    }

    case "flat": {
      variantStyles =
        "hover:bg-gray-100 transition-bg duration-500 bg-gray-50 text-gray-800 text-lg font-medium center p-1 rounded-md";
      break;
    }
    case "cancel": {
      variantStyles =
        "bg-red-500 hover:bg-red-600 transition-bg duration-500 center p-1 rounded-md shadowCustom font-bold";
      break;
    }

    default: {
      variantStyles =
        "font-exo text-white font-[800] uppercase outline:none hover:bg-yellow-600 bg-[#F2A206] transition-bg duration-500 text-white text-md center p-1 rounded-md shadowCustom";
      break;
    }
  }

  switch (size) {
    case "sm": {
      sizeStyle = "w-[120px] h-[40px] text-xs ";
      break;
    }

    case "large": {
      sizeStyle = "w-[210px] h-[50px] text-base";
      break;
    }

    case "xl": {
      sizeStyle = "w-[240px] h-[60px] text-lg";
      break;
    }

    default: {
      sizeStyle = "w-[180px] h-[45px] text-sm";
      break;
    }
  }

  const commonClasses = `
    flex gap-5
    ${clsx(sizeStyle, variantStyles, className)} 
    ${disabled ? "cursor-not-allowed hover opacity-80 no-hover" : ""}
    ${isPending ? "cursor-wait no-hover" : ""}
  `;

  if (type === "link" && href) {
    return (
      <Link
        to={href}
        className={commonClasses}
        onClick={onClick}
        aria-label={"lien vers =>" + href}
        {...(disabled ? { "aria-disabled": true } : {})}
      >
        {!isPending && withIcons && iconsPosition === "left" && (
          <span>{icon}</span>
        )}
        {isPending ? (
          <LoaderComponent size={size} variant={variant} />
        ) : (
          children
        )}
        {!isPending && withIcons && iconsPosition === "right" && (
          <span>{icon}</span>
        )}
      </Link>
    );
  }

  return (
    <button
      {...buttonProps}
      ref={ref}
      className={commonClasses}
      type={type}
      disabled={disabled || isPending}
      aria-label={"buttons"}
    >
      {!isPending && withIcons && iconsPosition === "left" && (
        <span>{icon}</span>
      )}
      {isPending ? <LoaderComponent size={size} variant={variant} /> : children}
      {!isPending && withIcons && iconsPosition === "right" && (
        <span>{icon}</span>
      )}
    </button>
  );
};

export default Buttons;
