// import React, { useCallback, useEffect, useMemo, useState } from "react";
// import {
//   Table,
//   TableHeader,
//   TableColumn,
//   TableBody,
//   TableRow,
//   TableCell,
//   Input,
//   Button,
//   Chip,
//   Pagination,
//   Spinner,
//   useDisclosure,
//   Drawer,
//   DrawerContent,
//   DrawerHeader,
//   DrawerBody,
//   DrawerFooter,
//   Dropdown,
//   DropdownTrigger,
//   DropdownMenu,
//   DropdownItem,
// } from "@heroui/react";
// import {
//   FiSearch,
//   FiPlus,
//   FiChevronDown,
//   FiEdit,
//   FiEye,
//   FiTrash2,
//   FiShoppingBag,
// } from "react-icons/fi";
// import { FaMoon, FaSun } from "react-icons/fa6";
// import ItemForm from "./ItemEditForm";
// import ItemShopMap from "./ItemShopMap";
// import {
//   getItemsData,
//   getShopData,
//   putItem,
//   sendNewItem,
// } from "../../../services/services";
// import { toast } from "react-toastify";
//
// const columns = [
//   { name: "ID", uid: "ID", sortable: true },
//   { name: "NAME", uid: "NAME", sortable: true },
//   { name: "PRICE", uid: "PRICE", sortable: true },
//   { name: "ICON", uid: "ICON" },
//   { name: "DATA", uid: "DATA" },
//   { name: "SHOPS", uid: "SHOPS" },
//   { name: "STATUS", uid: "ENABLED", sortable: true },
//   { name: "ACTIONS", uid: "actions" },
// ];
//
// const INITIAL_VISIBLE_COLUMNS = [
//   "ID",
//   "NAME",
//   "PRICE",
//   "SHOPS",
//   "ENABLED",
//   "actions",
// ];
//
// export default function ItemShopComponent() {
//   const [items, setItems] = useState([]);
//   const [shops, setShops] = useState([]);
//   const [filterValue, setFilterValue] = useState("");
//   const [page, setPage] = useState(1);
//   const [rowsPerPage, setRowsPerPage] = useState(5);
//   const [sortDescriptor, setSortDescriptor] = useState({
//     column: "NAME",
//     direction: "ascending",
//   });
//   const [theme, setTheme] = useState("dark");
//   const [visibleColumns, setVisibleColumns] = useState(
//     new Set(INITIAL_VISIBLE_COLUMNS),
//   );
//   const [selectedItem, setSelectedItem] = useState(null);
//
//   const addDrawer = useDisclosure();
//   const editDrawer = useDisclosure();
//   const shopDrawer = useDisclosure();
//   const deleteDrawer = useDisclosure();
//   const positionDrawer = useDisclosure();
//
//   const rowsPerPageOptions = [5, 20, 50, 75];
//
//   const hasSearchFilter = Boolean(filterValue);
//
//   const fetchShopData = async () => {
//     try {
//       const { data, response } = await getShopData();
//
//       if (response.status === 200) {
//         setShops(data);
//       }
//     } catch (e) {
//       console.log(e);
//     }
//   };
//
//   const fetchItemsData = async () => {
//     try {
//       const { data, response } = await getItemsData();
//
//       if (response.status === 200) {
//         setItems(data);
//       }
//     } catch (e) {
//       console.log(e);
//     }
//   };
//
//   useEffect(() => {
//     fetchShopData();
//     fetchItemsData();
//   }, []);
//
//   const handleDelete = (item) => {};
//
//   const handleEdit = (item) => {
//     setSelectedItem(item);
//     editDrawer.onOpen();
//   };
//
//   const handleViewPosition = (item) => {
//     setSelectedItem(item);
//     positionDrawer.onOpen();
//   };
//
//   const handleShopClick = (item) => {
//     setSelectedItem(item);
//     shopDrawer.onOpen();
//   };
//
//   const handleDeleteDrawer = (item) => {
//     setSelectedItem(item);
//     deleteDrawer.onOpen();
//   };
//
//   const handleSendNewItems = async (values) => {
//     try {
//       const { data, response } = await sendNewItem(values);
//       console.log(data);
//       if (response.status === 201) {
//         fetchItemsData();
//         toast.success("Item Ajouté !");
//         addDrawer.onClose();
//       } else {
//         toast.error("error !");
//       }
//     } catch (e) {
//       console.error(e);
//     }
//   };
//
//   const handleEditItems = async (values, id) => {
//     try {
//       const { data, response } = await putItem(id, values);
//
//       if (response.status === 200) {
//         fetchItemsData();
//         toast.success("Item modifié !");
//         editDrawer.onClose();
//       } else {
//         toast.error("error !");
//       }
//     } catch (e) {
//       console.error(e);
//     }
//   };
//
//   const handleDeleteItem = (values) => {};
//
//   let filteredItems = useMemo(() => {
//     let filtered = [...items];
//
//     if (hasSearchFilter) {
//       filtered = filtered.filter(
//         (item) =>
//           item.NAME.toLowerCase().includes(filterValue.toLowerCase()) ||
//           item.ID.toLowerCase().includes(filterValue.toLowerCase()) ||
//           item.SHOPS.some((shop) =>
//             shop.toLowerCase().includes(filterValue.toLowerCase()),
//           ) ||
//           item.ICON.toLowerCase().includes(filterValue.toLowerCase()),
//       );
//     }
//
//     return filtered;
//   }, [items, filterValue]);
//
//   const handleFilter = useCallback(
//     (selectedShop) => {
//       if (selectedShop) {
//         setFilterValue(selectedShop.CODE);
//       } else {
//         setFilterValue("");
//       }
//       shopDrawer.onClose();
//     },
//     [shopDrawer],
//   );
//
//   const pages = Math.ceil(filteredItems.length / rowsPerPage);
//
//   const visibleItems = useMemo(() => {
//     const start = (page - 1) * rowsPerPage;
//     const end = start + rowsPerPage;
//
//     return filteredItems.slice(start, end);
//   }, [page, filteredItems, rowsPerPage]);
//
//   const sortedItems = useMemo(() => {
//     return [...visibleItems].sort((a, b) => {
//       const first = a[sortDescriptor.column];
//       const second = b[sortDescriptor.column];
//       const cmp = first < second ? -1 : first > second ? 1 : 0;
//
//       return sortDescriptor.direction === "descending" ? -cmp : cmp;
//     });
//   }, [sortDescriptor, visibleItems]);
//
//   const renderCell = useCallback((item, columnKey) => {
//     const cellValue = item[columnKey];
//
//     switch (columnKey) {
//       case "ENABLED":
//         return (
//           <Chip
//             className="capitalize"
//             color={item.ENABLED ? "success" : "danger"}
//             size="sm"
//             variant="flat"
//           >
//             {item.ENABLED ? "Active" : "Inactive"}
//           </Chip>
//         );
//       case "SHOPS":
//         return (
//           <div className="flex gap-1">
//             {item.SHOPS.map((shop, index) => (
//               <Chip key={index} size="sm" variant="flat">
//                 {shop}
//               </Chip>
//             ))}
//           </div>
//         );
//       case "PRICE":
//         return `${cellValue} coins`;
//       case "actions":
//         return (
//           <div className="flex gap-2 justify-center">
//             <Button
//               isIconOnly
//               color="primary"
//               size="sm"
//               onClick={() => handleEdit(item)}
//             >
//               <FiEdit size={16} />
//             </Button>
//             <Button
//               isIconOnly
//               color="danger"
//               size="sm"
//               onClick={() => handleDeleteDrawer(item)}
//             >
//               <FiTrash2 size={16} />
//             </Button>
//             <Button
//               isIconOnly
//               className="bg-[#F5A524] text-white"
//               // variant="ghost"
//               size="sm"
//               onClick={() => handleViewPosition(item)}
//             >
//               <FiEye size={16} />
//             </Button>
//           </div>
//         );
//       default:
//         return cellValue;
//     }
//   }, []);
//
//   const topContent = useMemo(
//     () => (
//       <div className="flex flex-col gap-4">
//         <div className="flex justify-between gap-3 items-end">
//           <Input
//             isClearable
//             classNames={{
//               base: "w-full sm:max-w-[44%]",
//               inputWrapper:
//                 theme === "dark"
//                   ? "border-gray-700 text-white"
//                   : "border-gray-300",
//             }}
//             placeholder="Chercher un Item..."
//             size="sm"
//             startContent={
//               <FiSearch
//                 className={theme === "dark" ? "text-gray-500" : "text-gray-400"}
//               />
//             }
//             value={filterValue}
//             variant="bordered"
//             onClear={() => setFilterValue("")}
//             onValueChange={setFilterValue}
//           />
//           <div className="flex gap-3 items-center">
//             <Button
//               color="primary"
//               endContent={<FiPlus />}
//               onClick={addDrawer.onOpen}
//             >
//               Ajouter un Item
//             </Button>
//
//             <Button
//               className="bg-[#F5A524] text-white"
//               endContent={<FiShoppingBag />}
//               onClick={() => handleShopClick(null)}
//             >
//               Shops
//             </Button>
//             <Dropdown>
//               <DropdownTrigger>
//                 <Button
//                   variant="flat"
//                   className={
//                     theme === "dark"
//                       ? "bg-gray-700 text-gray-300"
//                       : "bg-gray-200 text-gray-700"
//                   }
//                 >
//                   Colonne active <FiChevronDown />
//                 </Button>
//               </DropdownTrigger>
//               <DropdownMenu
//                 disallowEmptySelection
//                 aria-label="Table Columns"
//                 closeOnSelect={false}
//                 selectedKeys={visibleColumns}
//                 selectionMode="multiple"
//                 onSelectionChange={setVisibleColumns}
//               >
//                 {columns.map((column) => (
//                   <DropdownItem key={column.uid} className="capitalize">
//                     {column.name}
//                   </DropdownItem>
//                 ))}
//               </DropdownMenu>
//             </Dropdown>
//             <Button
//               isIconOnly
//               variant="flat"
//               onClick={() =>
//                 setTheme((prev) => (prev === "dark" ? "light" : "dark"))
//               }
//               className={
//                 theme === "dark"
//                   ? "bg-gray-700 text-gray-300"
//                   : "bg-gray-200 text-gray-700"
//               }
//             >
//               {theme === "dark" ? <FaSun size={20} /> : <FaMoon size={20} />}
//             </Button>
//           </div>
//         </div>
//
//         <div className="flex justify-between items-center">
//           <span className="text-default-400 text-small">
//             Total {filteredItems.length} Items
//           </span>
//           <label className="flex items-center text-default-400 text-small">
//             Résultat par page:
//             <select
//               className="pl-2 bg-transparent outline-none text-default-400 text-small cursor-pointer"
//               value={rowsPerPage}
//               onChange={(e) => setRowsPerPage(Number(e.target.value))}
//             >
//               {rowsPerPageOptions.map((item, index) => (
//                 <option key={index} value={item}>
//                   {item}
//                 </option>
//               ))}
//             </select>
//           </label>
//         </div>
//       </div>
//     ),
//     [
//       filterValue,
//       theme,
//       rowsPerPage,
//       visibleColumns,
//       page,
//       pages,
//       items.length,
//     ],
//   );
//
//   const bottomContent = useMemo(
//     () => (
//       <div className="py-2 px-2 flex justify-between items-center">
//         <Pagination
//           isCompact
//           showControls
//           showShadow
//           color="primary"
//           page={page}
//           total={pages}
//           onChange={setPage}
//         />
//       </div>
//     ),
//     [filteredItems.length, page, pages],
//   );
//
//   return (
//     <div
//       className={`space-y-4 ${theme === "dark" ? "bg-gray-900" : "bg-white"} p-8 transition-colors duration-300`}
//     >
//       <Table
//         aria-label="Item shop table"
//         bottomContent={bottomContent}
//         bottomContentPlacement="outside"
//         classNames={{
//           wrapper: `max-h-[50vh] ${theme === "dark" ? "bg-gray-900 text-gray-200" : "bg-white text-gray-800"}`,
//           th:
//             theme === "dark"
//               ? "bg-gray-800 text-gray-300 border-b border-gray-700"
//               : "bg-gray-100 text-gray-700 border-b border-gray-300",
//           td: theme === "dark" ? "text-gray-400" : "text-gray-600",
//           tr:
//             theme === "dark"
//               ? "border-b border-gray-800 hover:bg-gray-700"
//               : "border-b border-gray-300 hover:bg-gray-200",
//         }}
//         sortDescriptor={sortDescriptor}
//         topContent={topContent}
//         topContentPlacement="outside"
//         onSortChange={setSortDescriptor}
//       >
//         <TableHeader
//           columns={columns.filter((column) =>
//             Array.from(visibleColumns).includes(column.uid),
//           )}
//         >
//           {(column) => (
//             <TableColumn
//               key={column.uid}
//               align={column.uid === "actions" ? "center" : "start"}
//               allowsSorting={column.sortable}
//             >
//               {column.name}
//             </TableColumn>
//           )}
//         </TableHeader>
//         <TableBody
//           emptyContent={"No items found"}
//           items={sortedItems}
//           loadingContent={<Spinner />}
//         >
//           {(item) => (
//             <TableRow key={item._id}>
//               {(columnKey) => (
//                 <TableCell>{renderCell(item, columnKey)}</TableCell>
//               )}
//             </TableRow>
//           )}
//         </TableBody>
//       </Table>
//
//       {/* Add Drawer */}
//       <Drawer
//         isOpen={addDrawer.isOpen}
//         onClose={addDrawer.onClose}
//         placement="left"
//         size="5xl"
//         backdrop={"blur"}
//       >
//         <DrawerContent
//           className={theme === "dark" ? "bg-gray-800" : "bg-white"}
//         >
//           <DrawerHeader
//             className={theme === "dark" ? "text-gray-200" : "text-gray-800"}
//           >
//             Ajouter un nouvelle item
//           </DrawerHeader>
//           <DrawerBody>
//             <ItemForm
//               onSubmit={(data) => {
//                 handleSendNewItems(data);
//               }}
//               onCancel={addDrawer.onClose}
//               theme={theme}
//               shops={shops}
//             />
//           </DrawerBody>
//         </DrawerContent>
//       </Drawer>
//
//       {/* Edit Drawer */}
//       <Drawer
//         isOpen={editDrawer.isOpen}
//         onClose={editDrawer.onClose}
//         placement="right"
//         size="5xl"
//         backdrop={"blur"}
//       >
//         <DrawerContent
//           className={theme === "dark" ? "bg-gray-800" : "bg-white"}
//         >
//           <DrawerHeader
//             className={theme === "dark" ? "text-gray-200" : "text-gray-800"}
//           >
//             Edition de l'item !
//           </DrawerHeader>
//           <DrawerBody>
//             <ItemForm
//               item={selectedItem}
//               onSubmit={(data) => {
//                 handleEditItems(data, selectedItem._id);
//               }}
//               onCancel={editDrawer.onClose}
//               theme={theme}
//               shops={shops}
//             />
//           </DrawerBody>
//         </DrawerContent>
//       </Drawer>
//
//       {/* Shop Drawer */}
//       <Drawer
//         isOpen={shopDrawer.isOpen}
//         onClose={shopDrawer.onClose}
//         placement="top"
//         backdrop={"blur"}
//         size="full"
//       >
//         <DrawerContent
//           className={theme === "dark" ? "bg-gray-800" : "bg-white"}
//         >
//           <DrawerBody>
//             <div className="flex flex-col gap-4">
//               <div className="">
//                 <ItemShopMap
//                   onFilter={handleFilter}
//                   theme={theme}
//                   shops={shops}
//                 />
//               </div>
//             </div>
//           </DrawerBody>
//           <DrawerFooter>
//             <Button color="danger" variant="flat" onPress={shopDrawer.onClose}>
//               Fermer
//             </Button>
//           </DrawerFooter>
//         </DrawerContent>
//       </Drawer>
//
//       {/* Position Drawer */}
//       <Drawer
//         isOpen={positionDrawer.isOpen}
//         onClose={positionDrawer.onClose}
//         placement="right"
//         size="5xl"
//         backdrop={"blur"}
//       >
//         <DrawerContent
//           className={theme === "dark" ? "bg-gray-800" : "bg-white"}
//         >
//           <DrawerHeader
//             className={theme === "dark" ? "text-gray-200" : "text-gray-800"}
//           >
//             Position des shops
//           </DrawerHeader>
//           <DrawerBody>
//             {selectedItem && (
//               <ItemShopMap
//                 theme={theme}
//                 selectedItem={selectedItem}
//                 shops={shops}
//                 isItemPosition={true}
//               />
//             )}
//           </DrawerBody>
//           <DrawerFooter>
//             <div className="flex justify-end gap-2">
//               <Button
//                 color="danger"
//                 variant="flat"
//                 onPress={positionDrawer.onClose}
//               >
//                 Fermer
//               </Button>
//             </div>
//           </DrawerFooter>
//         </DrawerContent>
//       </Drawer>
//
//       {/*  Suppression Drawer*/}
//     </div>
//   );
// }
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Input,
  Button,
  Chip,
  Pagination,
  Spinner,
  useDisclosure,
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
} from "@heroui/react";
import {
  FiSearch,
  FiPlus,
  FiChevronDown,
  FiEdit,
  FiEye,
  FiTrash2,
  FiShoppingBag,
} from "react-icons/fi";
import { FaMoon, FaSun } from "react-icons/fa6";
import ItemForm from "./ItemEditForm";
import ItemShopMap from "./ItemShopMap";
import {
  getItemsData,
  getShopData,
  putItem,
  sendNewItem,
  deleteItem,
} from "../../../services/services";
import { toast } from "react-toastify";

const columns = [
  { name: "ID", uid: "ID", sortable: true },
  { name: "NAME", uid: "NAME", sortable: true },
  { name: "PRICE", uid: "PRICE", sortable: true },
  { name: "ICON", uid: "ICON" },
  { name: "DATA", uid: "DATA" },
  { name: "SHOPS", uid: "SHOPS" },
  { name: "STATUS", uid: "ENABLED", sortable: true },
  { name: "ACTIONS", uid: "actions" },
];

const INITIAL_VISIBLE_COLUMNS = [
  "ID",
  "NAME",
  "PRICE",
  "SHOPS",
  "ENABLED",
  "actions",
];

export default function ItemShopComponent() {
  const [items, setItems] = useState([]);
  const [shops, setShops] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [sortDescriptor, setSortDescriptor] = useState({
    column: "NAME",
    direction: "ascending",
  });
  const [theme, setTheme] = useState("dark");
  const [visibleColumns, setVisibleColumns] = useState(
    new Set(INITIAL_VISIBLE_COLUMNS),
  );
  const [selectedItem, setSelectedItem] = useState(null);

  const addDrawer = useDisclosure();
  const editDrawer = useDisclosure();
  const shopDrawer = useDisclosure();
  const deleteDrawer = useDisclosure();
  const positionDrawer = useDisclosure();

  const rowsPerPageOptions = [5, 20, 50, 75];

  const hasSearchFilter = Boolean(filterValue);

  const fetchShopData = async () => {
    try {
      const { data, response } = await getShopData();

      if (response.status === 200) {
        setShops(data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const fetchItemsData = async () => {
    try {
      const { data, response } = await getItemsData();

      if (response.status === 200) {
        setItems(data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchShopData();
    fetchItemsData();
  }, []);

  const handleDelete = (item) => {};

  const handleEdit = (item) => {
    setSelectedItem(item);
    editDrawer.onOpen();
  };

  const handleViewPosition = (item) => {
    setSelectedItem(item);
    positionDrawer.onOpen();
  };

  const handleShopClick = (item) => {
    setSelectedItem(item);
    shopDrawer.onOpen();
  };

  const handleDeleteDrawer = (item) => {
    setSelectedItem(item);
    deleteDrawer.onOpen();
  };

  const handleSendNewItems = async (values) => {
    try {
      const { data, response } = await sendNewItem(values);
      console.log(data);
      if (response.status === 201) {
        fetchItemsData();
        toast.success("Item Ajouté !");
        addDrawer.onClose();
      } else {
        toast.error("error !");
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleEditItems = async (values, id) => {
    try {
      const { data, response } = await putItem(id, values);

      if (response.status === 200) {
        fetchItemsData();
        toast.success("Item modifié !");
        editDrawer.onClose();
      } else {
        toast.error("error !");
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleDeleteItem = async (id) => {
    try {
      const { data, response } = await deleteItem(id);
      if (response.status === 200) {
        fetchItemsData();
        toast.success("Item supprimé !");
        deleteDrawer.onClose();
      } else {
        toast.error("error !");
      }
    } catch (e) {
      console.error(e);
    }
  };

  const filteredItems = useMemo(() => {
    let filtered = [...items];

    if (hasSearchFilter) {
      filtered = filtered.filter(
        (item) =>
          item.NAME.toLowerCase().includes(filterValue.toLowerCase()) ||
          item.ID.toLowerCase().includes(filterValue.toLowerCase()) ||
          item.SHOPS.some((shop) =>
            shop.toLowerCase().includes(filterValue.toLowerCase()),
          ) ||
          item.ICON.toLowerCase().includes(filterValue.toLowerCase()),
      );
    }

    return filtered;
  }, [items, filterValue]);

  const handleFilter = useCallback(
    (selectedShop) => {
      if (selectedShop) {
        setFilterValue(selectedShop.CODE);
      } else {
        setFilterValue("");
      }
      shopDrawer.onClose();
    },
    [shopDrawer],
  );

  const pages = Math.ceil(filteredItems.length / rowsPerPage);

  const visibleItems = useMemo(() => {
    const start = (page - 1) * rowsPerPage;
    const end = start + rowsPerPage;

    return filteredItems.slice(start, end);
  }, [page, filteredItems, rowsPerPage]);

  const sortedItems = useMemo(() => {
    return [...visibleItems].sort((a, b) => {
      const first = a[sortDescriptor.column];
      const second = b[sortDescriptor.column];
      const cmp = first < second ? -1 : first > second ? 1 : 0;

      return sortDescriptor.direction === "descending" ? -cmp : cmp;
    });
  }, [sortDescriptor, visibleItems]);

  const renderCell = useCallback((item, columnKey) => {
    const cellValue = item[columnKey];

    switch (columnKey) {
      case "ENABLED":
        return (
          <Chip
            className="capitalize"
            color={item.ENABLED ? "success" : "danger"}
            size="sm"
            variant="flat"
          >
            {item.ENABLED ? "Active" : "Inactive"}
          </Chip>
        );
      case "SHOPS":
        return (
          <div className="flex gap-1">
            {item.SHOPS.map((shop, index) => (
              <Chip key={index} size="sm" variant="flat">
                {shop}
              </Chip>
            ))}
          </div>
        );
      case "PRICE":
        return `${cellValue} coins`;
      case "actions":
        return (
          <div className="flex gap-2 justify-center">
            <Button
              isIconOnly
              color="primary"
              size="sm"
              onClick={() => handleEdit(item)}
            >
              <FiEdit size={16} />
            </Button>
            <Button
              isIconOnly
              color="danger"
              size="sm"
              onClick={() => handleDeleteDrawer(item)}
            >
              <FiTrash2 size={16} />
            </Button>
            <Button
              isIconOnly
              className="bg-[#F5A524] text-white"
              // variant="ghost"
              size="sm"
              onClick={() => handleViewPosition(item)}
            >
              <FiEye size={16} />
            </Button>
          </div>
        );
      default:
        return cellValue;
    }
  }, []);

  const topContent = useMemo(
    () => (
      <div className="flex flex-col gap-4">
        <div className="flex justify-between gap-3 items-end">
          <Input
            isClearable
            classNames={{
              base: "w-full sm:max-w-[44%]",
              inputWrapper:
                theme === "dark"
                  ? "border-gray-700 text-white"
                  : "border-gray-300",
            }}
            placeholder="Chercher un Item..."
            size="sm"
            startContent={
              <FiSearch
                className={theme === "dark" ? "text-gray-500" : "text-gray-400"}
              />
            }
            value={filterValue}
            variant="bordered"
            onClear={() => setFilterValue("")}
            onValueChange={setFilterValue}
          />
          <div className="flex gap-3 items-center">
            <Button
              color="primary"
              endContent={<FiPlus />}
              onClick={addDrawer.onOpen}
            >
              Ajouter un Item
            </Button>

            <Button
              className="bg-[#F5A524] text-white"
              endContent={<FiShoppingBag />}
              onClick={() => handleShopClick(null)}
            >
              Shops
            </Button>
            <Dropdown>
              <DropdownTrigger>
                <Button
                  variant="flat"
                  className={
                    theme === "dark"
                      ? "bg-gray-700 text-gray-300"
                      : "bg-gray-200 text-gray-700"
                  }
                >
                  Colonne active <FiChevronDown />
                </Button>
              </DropdownTrigger>
              <DropdownMenu
                disallowEmptySelection
                aria-label="Table Columns"
                closeOnSelect={false}
                selectedKeys={visibleColumns}
                selectionMode="multiple"
                onSelectionChange={setVisibleColumns}
              >
                {columns.map((column) => (
                  <DropdownItem key={column.uid} className="capitalize">
                    {column.name}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
            <Button
              isIconOnly
              variant="flat"
              onClick={() =>
                setTheme((prev) => (prev === "dark" ? "light" : "dark"))
              }
              className={
                theme === "dark"
                  ? "bg-gray-700 text-gray-300"
                  : "bg-gray-200 text-gray-700"
              }
            >
              {theme === "dark" ? <FaSun size={20} /> : <FaMoon size={20} />}
            </Button>
          </div>
        </div>

        <div className="flex justify-between items-center">
          <span className="text-default-400 text-small">
            Total {filteredItems.length} Items
          </span>
          <label className="flex items-center text-default-400 text-small">
            Résultat par page:
            <select
              className="pl-2 bg-transparent outline-none text-default-400 text-small cursor-pointer"
              value={rowsPerPage}
              onChange={(e) => setRowsPerPage(Number(e.target.value))}
            >
              {rowsPerPageOptions.map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </label>
        </div>
      </div>
    ),
    [
      filterValue,
      theme,
      rowsPerPage,
      visibleColumns,
      page,
      pages,
      items.length,
    ],
  );

  const bottomContent = useMemo(
    () => (
      <div className="py-2 px-2 flex justify-between items-center">
        <Pagination
          isCompact
          showControls
          showShadow
          color="primary"
          page={page}
          total={pages}
          onChange={setPage}
        />
      </div>
    ),
    [filteredItems.length, page, pages],
  );

  return (
    <div
      className={`space-y-4 ${theme === "dark" ? "bg-gray-900" : "bg-white"} p-8 transition-colors duration-300`}
    >
      <Table
        aria-label="Item shop table"
        bottomContent={bottomContent}
        bottomContentPlacement="outside"
        classNames={{
          wrapper: `max-h-[50vh] ${theme === "dark" ? "bg-gray-900 text-gray-200" : "bg-white text-gray-800"}`,
          th:
            theme === "dark"
              ? "bg-gray-800 text-gray-300 border-b border-gray-700"
              : "bg-gray-100 text-gray-700 border-b border-gray-300",
          td: theme === "dark" ? "text-gray-400" : "text-gray-600",
          tr:
            theme === "dark"
              ? "border-b border-gray-800 hover:bg-gray-700"
              : "border-b border-gray-300 hover:bg-gray-200",
        }}
        sortDescriptor={sortDescriptor}
        topContent={topContent}
        topContentPlacement="outside"
        onSortChange={setSortDescriptor}
      >
        <TableHeader
          columns={columns.filter((column) =>
            Array.from(visibleColumns).includes(column.uid),
          )}
        >
          {(column) => (
            <TableColumn
              key={column.uid}
              align={column.uid === "actions" ? "center" : "start"}
              allowsSorting={column.sortable}
            >
              {column.name}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody
          emptyContent={"No items found"}
          items={sortedItems}
          loadingContent={<Spinner />}
        >
          {(item) => (
            <TableRow key={item._id}>
              {(columnKey) => (
                <TableCell>{renderCell(item, columnKey)}</TableCell>
              )}
            </TableRow>
          )}
        </TableBody>
      </Table>

      {/* Add Drawer */}
      <Drawer
        isOpen={addDrawer.isOpen}
        onClose={addDrawer.onClose}
        placement="left"
        size="5xl"
        backdrop={"blur"}
      >
        <DrawerContent
          className={theme === "dark" ? "bg-gray-800" : "bg-white"}
        >
          <DrawerHeader
            className={theme === "dark" ? "text-gray-200" : "text-gray-800"}
          >
            Ajouter un nouvelle item
          </DrawerHeader>
          <DrawerBody>
            <ItemForm
              onSubmit={(data) => {
                handleSendNewItems(data);
              }}
              onCancel={addDrawer.onClose}
              theme={theme}
              shops={shops}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      {/* Edit Drawer */}
      <Drawer
        isOpen={editDrawer.isOpen}
        onClose={editDrawer.onClose}
        placement="right"
        size="5xl"
        backdrop={"blur"}
      >
        <DrawerContent
          className={theme === "dark" ? "bg-gray-800" : "bg-white"}
        >
          <DrawerHeader
            className={theme === "dark" ? "text-gray-200" : "text-gray-800"}
          >
            Edition de l'item !
          </DrawerHeader>
          <DrawerBody>
            <ItemForm
              item={selectedItem}
              onSubmit={(data) => {
                handleEditItems(data, selectedItem._id);
              }}
              onCancel={editDrawer.onClose}
              theme={theme}
              shops={shops}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      {/* Shop Drawer */}
      <Drawer
        isOpen={shopDrawer.isOpen}
        onClose={shopDrawer.onClose}
        placement="top"
        backdrop={"blur"}
        size="full"
      >
        <DrawerContent
          className={theme === "dark" ? "bg-gray-800" : "bg-white"}
        >
          <DrawerBody>
            <div className="flex flex-col gap-4">
              <div className="">
                <ItemShopMap
                  onFilter={handleFilter}
                  theme={theme}
                  shops={shops}
                />
              </div>
            </div>
          </DrawerBody>
          <DrawerFooter>
            <Button color="danger" variant="flat" onPress={shopDrawer.onClose}>
              Fermer
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

      {/* Position Drawer */}
      <Drawer
        isOpen={positionDrawer.isOpen}
        onClose={positionDrawer.onClose}
        placement="right"
        size="5xl"
        backdrop={"blur"}
      >
        <DrawerContent
          className={theme === "dark" ? "bg-gray-800" : "bg-white"}
        >
          <DrawerHeader
            className={theme === "dark" ? "text-gray-200" : "text-gray-800"}
          >
            Position des shops
          </DrawerHeader>
          <DrawerBody>
            {selectedItem && (
              <ItemShopMap
                theme={theme}
                selectedItem={selectedItem}
                shops={shops}
                isItemPosition={true}
              />
            )}
          </DrawerBody>
          <DrawerFooter>
            <div className="flex justify-end gap-2">
              <Button
                color="danger"
                variant="flat"
                onPress={positionDrawer.onClose}
              >
                Fermer
              </Button>
            </div>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

      {/*  Suppression Drawer*/}
      <Drawer
        isOpen={deleteDrawer.isOpen}
        onClose={deleteDrawer.onClose}
        placement="bottom"
        backdrop={"blur"}
        size="md"
      >
        <DrawerContent
          className={theme === "dark" ? "bg-gray-800" : "bg-white"}
        >
          <DrawerHeader
            className={theme === "dark" ? "text-gray-200" : "text-gray-800"}
          >
            Supprimer l'item "{selectedItem?.NAME}" ?
          </DrawerHeader>
          <DrawerBody>
            <p className={theme === "dark" ? "text-gray-400" : "text-gray-600"}>
              Êtes-vous sûr de vouloir supprimer cet item ? Cette action est
              irréversible.
            </p>
          </DrawerBody>
          <DrawerFooter>
            <div className="flex justify-end gap-2">
              <Button
                color="danger"
                variant="flat"
                onClick={() => handleDeleteItem(selectedItem?._id)}
              >
                Supprimer
              </Button>
              <Button
                color="primary"
                variant="flat"
                onClick={deleteDrawer.onClose}
              >
                Annuler
              </Button>
            </div>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </div>
  );
}
