import React, { useState } from "react";
import {
  Input,
  Button,
  Checkbox,
  Select,
  SelectItem,
  Card,
  CardBody,
  CardHeader,
  Chip,
} from "@heroui/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import InfoToolTips from "../../../components/InfoTool/InfoToolTips";
import InfoTooltip from "../../../components/InfoTool/InfoToolTips";

const ItemSchema = Yup.object().shape({
  ID: Yup.string().required("ID is required"),
  NAME: Yup.string().required("Name is required"),
  PRICE: Yup.number()
    .required("Price is required")
    .min(0, "Price must be positive"),
  ICON: Yup.string().required("Icon is required"),
  DATA: Yup.string().required("Data is required"),
  SHOPS: Yup.array().of(Yup.string()).min(1, "Select at least one shop"),
  ENABLED: Yup.boolean(),
});

const ItemForm = ({ item, onSubmit, onCancel, theme, shops }) => {
  const availableShops = shops.map((item) => item.CODE);
  const availableIcons = [
    "IRON_HOE",
    "IRON_SWORD",
    "DIAMOND_SWORD",
    "STONE_HOE",
  ];

  const formik = useFormik({
    initialValues: {
      ID: item?.ID || "",
      NAME: item?.NAME || "",
      PRICE: item?.PRICE || 0,
      DATA: item?.DATA || 0,
      ICON: item?.ICON || "",
      ENABLED: item?.ENABLED ?? true,
      SHOPS: item?.SHOPS || [],
    },
    validationSchema: ItemSchema,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  const handleShopSelect = (e) => {
    const selectedShop = e.target.value;
    if (!formik.values.SHOPS.includes(selectedShop)) {
      formik.setFieldValue("SHOPS", [...formik.values.SHOPS, selectedShop]);
    }
  };

  const removeShop = (shopToRemove) => {
    formik.setFieldValue(
      "SHOPS",
      formik.values.SHOPS.filter((shop) => shop !== shopToRemove),
    );
  };

  return (
    <form onSubmit={formik.handleSubmit} className="space-y-6">
      {/* Basic Information */}
      <Card
        className={`${
          theme === "dark" ? "bg-gray-800" : "bg-white"
        } shadow-none`}
      >
        <CardBody className="space-y-4">
          <Input
            label="ID"
            name="ID"
            value={formik.values.ID.toUpperCase()}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorMessage={formik.touched.ID && formik.errors.ID}
            isInvalid={formik.touched.ID && Boolean(formik.errors.ID)}
            classNames={{
              input: theme === "dark" ? "text-white" : "text-gray-800",
              label: theme === "dark" ? "text-gray-300" : "text-gray-700",
            }}
          />

          <Input
            type="texte"
            label="Nom"
            name="NAME"
            value={formik.values.NAME}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorMessage={formik.touched.NAME && formik.errors.NAME}
            isInvalid={formik.touched.NAME && Boolean(formik.errors.NAME)}
            classNames={{
              input: theme === "dark" ? "text-white" : "text-gray-800",
              label: theme === "dark" ? "text-gray-300" : "text-gray-700",
            }}
          />

          <Input
            type="number"
            label="Prix"
            name="PRICE"
            value={formik.values.PRICE}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorMessage={formik.touched.PRICE && formik.errors.PRICE}
            isInvalid={formik.touched.PRICE && Boolean(formik.errors.PRICE)}
            classNames={{
              input: theme === "dark" ? "text-white" : "text-gray-800",
              label: theme === "dark" ? "text-gray-300" : "text-gray-700",
            }}
          />
        </CardBody>
      </Card>
      <Card
        className={`${
          theme === "dark" ? "bg-gray-800" : "bg-white"
        } shadow-none overflow-visible`}
      >
        <CardHeader className="flex items-center justify-between">
          <h3
            className={`text-lg font-semibold ${
              theme === "dark" ? "text-gray-200" : "text-gray-800"
            }`}
          >
            Model:
          </h3>
          <InfoTooltip
            theme={theme}
            title="Organisation des Icônes"
            text={
              <>
                <ul>
                  <li>
                    <strong>Chapeau :</strong> ICON "FER DE HOUE" (IRON HOE)
                    pour les outils
                  </li>
                  <li>
                    <strong>Objet (arme) :</strong> ICON "ÉPÉE EN FER" (IRON
                    SWORD) pour les armes
                  </li>
                  <li>
                    <strong>Décoration :</strong> ICON "HOUE EN PIERRE" (STONE
                    HOE) pour les décorations
                  </li>
                  <li>
                    <strong>Attraction :</strong> ICON "ÉPÉE EN DIAMANT"
                    (DIAMOND SWORD) pour les éléments spéciaux
                  </li>
                </ul>
                <p className="pt-5">
                  Il est important de respecter ce code d'organisation des
                  icônes pour garantir le bon fonctionnement des interfaces dans
                  le jeu et assurer une expérience fluide et cohérente pour les
                  utilisateurs.
                </p>
              </>
            }
          />
        </CardHeader>
        <CardBody className="flex flex-col items-center gap-5">
          <Select
            label="Icon"
            placeholder="Choisir un icons"
            selectedKeys={formik.values.ICON ? [formik.values.ICON] : []}
            onChange={(e) => formik.setFieldValue("ICON", e.target.value)}
            errorMessage={formik.touched.ICON && formik.errors.ICON}
            isInvalid={formik.touched.ICON && Boolean(formik.errors.ICON)}
            classNames={{
              trigger: "bg-white",
              value: theme === "dark" ? "text-white" : "text-gray-800",
              label: theme === "dark" ? "text-gray-300" : "text-gray-700",
            }}
          >
            {availableIcons.map((icon) => (
              <SelectItem key={icon} value={icon}>
                {icon}
              </SelectItem>
            ))}
          </Select>
          <Input
            type="number"
            label="Data"
            name="DATA"
            value={formik.values.DATA}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorMessage={formik.touched.DATA && formik.errors.DATA}
            isInvalid={formik.touched.DATA && Boolean(formik.errors.DATA)}
            classNames={{
              input: theme === "dark" ? "text-white" : "text-gray-800",
              label: theme === "dark" ? "text-gray-300" : "text-gray-700",
            }}
          />
        </CardBody>
      </Card>

      {/* Shops Selection */}
      <Card
        className={`${
          theme === "dark" ? "bg-gray-800" : "bg-white"
        } shadow-none`}
      >
        <CardHeader>
          <h3
            className={`text-lg font-semibold ${
              theme === "dark" ? "text-gray-200" : "text-gray-800"
            }`}
          >
            Shop:
          </h3>
        </CardHeader>
        <CardBody>
          <div className="space-y-4">
            <Select
              label="Shop"
              placeholder="Choisir un shop"
              onChange={handleShopSelect}
              errorMessage={formik.touched.SHOPS && formik.errors.SHOPS}
              isInvalid={formik.touched.SHOPS && Boolean(formik.errors.SHOPS)}
              classNames={{
                trigger: "bg-white",
                value: theme === "text-gray-800",
                label: theme === "text-gray-700",
              }}
            >
              {availableShops.map((shop) => (
                <SelectItem key={shop} value={shop}>
                  {shop}
                </SelectItem>
              ))}
            </Select>

            <div className="flex flex-wrap gap-2 mt-2">
              {formik.values.SHOPS.map((shop) => (
                <Chip
                  key={shop}
                  onClose={() => removeShop(shop)}
                  variant="bordered"
                  className={theme === "dark" ? "text-white" : "text-gray-800"}
                >
                  {shop}
                </Chip>
              ))}
            </div>
          </div>
        </CardBody>
      </Card>

      {/* Status */}
      <Card
        className={`${
          theme === "dark" ? "bg-gray-800" : "bg-white"
        } shadow-none`}
      >
        <CardHeader>
          <h3
            className={`text-lg font-semibold ${
              theme === "dark" ? "text-gray-200" : "text-gray-800"
            }`}
          >
            Status
          </h3>
        </CardHeader>
        <CardBody>
          <Checkbox
            isSelected={formik.values.ENABLED}
            onChange={(e) => formik.setFieldValue("ENABLED", e.target.checked)}
            classNames={{
              label: theme === "dark" ? "text-gray-300" : "text-gray-700",
            }}
          >
            Actif
          </Checkbox>
        </CardBody>
      </Card>

      {/* Form Actions */}
      <div className="flex justify-end gap-2">
        <Button color="danger" variant="flat" onPress={onCancel}>
          Annuler
        </Button>
        <Button color="primary" type="submit">
          Envoyer
        </Button>
      </div>
    </form>
  );
};

export default ItemForm;
