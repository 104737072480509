import { useEffect, useState } from "react";
import "leaflet/dist/leaflet.css";
import {
  ImageOverlay,
  MapContainer,
  Polyline,
  Marker,
  Tooltip,
  useMapEvents,
} from "react-leaflet";
import L from "leaflet";
import map from "./Map/MAP2.png";
import { Button, Card } from "@heroui/react";
import {
  convertCoordinates,
  createGridLines,
  reverseConvertCoordinates,
} from "./Map/MapUtils";
import { parseLocation } from "../../../utils/utils";
import { IoMdGrid } from "react-icons/io";
import { AiOutlineBorder } from "react-icons/ai";
import { clsx } from "clsx";
import { IoLocationSharp } from "react-icons/io5";
import { BsCircleFill } from "react-icons/bs";
import { FcShop } from "react-icons/fc";
import { shopIcons } from "../../../utils/svg";
// Supprimer l'ancienne icône par défaut
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  iconSize: [23, 35],
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const GridComponents = () => (
  <>
    {createGridLines(16, 1024).map((line, index) => (
      <Polyline
        key={`chunk-${index}`}
        positions={line}
        color="#323232"
        weight={0.5}
      />
    ))}
    {createGridLines(512, 1024).map((line, index) => (
      <Polyline
        key={`region-${index}`}
        positions={line}
        color="black"
        weight={0.9}
      />
    ))}
  </>
);

const ItemShopMap = ({
  shops,
  onFilter,
  theme,
  selectedItem,
  isItemPosition,
}) => {
  const [cursorCoords, setCursorCoords] = useState({ x: 0, z: 0 });
  const [showGrid, setShowGrid] = useState(true);
  const [hoveredShop, setHoveredShop] = useState(null);
  const [isDarkMode, setIsDarkMode] = useState(false); // Update 2
  const [filteredShops, setFilteredShops] = useState(shops);

  useEffect(() => {
    if (isItemPosition && selectedItem?.SHOPS) {
      setFilteredShops(
        shops.filter((item) => selectedItem?.SHOPS.includes(item.CODE)),
      );
    } else {
      setFilteredShops(shops);
    }
  }, [selectedItem, isItemPosition, shops]);

  useEffect(() => {
    if (theme === "dark") {
      return setIsDarkMode(true);
    } else {
      return setIsDarkMode(false);
    }
  }, [theme]);

  const center = [0, 0];
  const bounds = [
    [-1024, -1024],
    [1024, 1024],
  ];

  const MapEvents = () => {
    useMapEvents({
      mousemove: (e) => {
        const { lat, lng } = e.latlng;
        const { x, z } = reverseConvertCoordinates(lat, lng);
        setCursorCoords({ x: Math.round(x), z: Math.round(z) });
      },
    });
    return null;
  };

  const itemMarker = filteredShops.map((shop, index) => {
    const { x, z } = parseLocation(shop.LOCATION);
    const position = convertCoordinates(x, z);
    const isHovered = hoveredShop === shop.CODE;
    const name = shop.NAME.replace("&a&l", "");
    return (
      <Marker
        key={index}
        position={position}
        icon={L.divIcon({
          className: "",
          html: `
          <span class="relative flex items-center justify-center size-4">
            <span class="absolute inline-flex h-full w-full ${isHovered ? "animate-ping" : ""} bg-black rounded-full bg-opacity-75"></span>
            <span class="absolute inline-flex w-4 h-4 rounded-full bg-black border border-black"></span>
          </span>
        `,
          iconSize: [25, 25],
        })}
        eventHandlers={{
          mouseover: () => setHoveredShop(shop.CODE),
          mouseout: () => setHoveredShop(null),
          click: () => onFilter(shop),
        }}
      >
        <Tooltip permanent={isHovered}>{name}</Tooltip>
      </Marker>
    );
  });

  return (
    <section className="w-full max-h-[80vh] flex justify-between ">
      <MapContainer
        maxBounds={bounds}
        center={center}
        zoom={-1}
        minZoom={-1}
        maxZoom={3}
        className="w-[55%] object-cover"
        crs={L.CRS.Simple}
      >
        <ImageOverlay url={map} bounds={bounds} />
        <MapEvents />
        {showGrid && <GridComponents />}

        {itemMarker}

        <div className="absolute top-2 right-2 bg-white p-2 rounded shadow z-[1000]">
          X: {cursorCoords.x}, Z: {cursorCoords.z}
        </div>

        <div className="absolute top-2 right-28 bg-white rounded shadow z-[1000]">
          <Button
            isIconOnly
            aria-label="grid"
            variant="flat"
            onPress={() => setShowGrid(!showGrid)}
          >
            {showGrid ? <AiOutlineBorder /> : <IoMdGrid />}
          </Button>
        </div>
      </MapContainer>

      <div className="min-h-[80vh]  w-[40%] overflow-scroll">
        <h3
          className={clsx(
            theme === "dark" ? "text-gray-200" : "text-gray-800",
            "underline pb-2",
          )}
        >
          Liste des Shops:
        </h3>
        <div className="flex flex-col gap-2 w-full ">
          {filteredShops.map((shop, index) => {
            const location = parseLocation(shop.LOCATION);
            const name = shop.NAME.replace("&a&l", "");
            const isHovered = hoveredShop === shop.CODE;

            return (
              <Card key={index} className="rounded-none">
                <div
                  className={clsx(
                    "p-3 cursor-pointer transition-all duration-200",
                    isDarkMode
                      ? `bg-gray-700 hover:bg-gray-600 ${isHovered ? "bg-gray-900" : ""}`
                      : `bg-white hover:bg-gray-200 ${isHovered ? "bg-blue-100" : ""}`,
                    "",
                    "transform hover:scale-102",
                  )}
                  onMouseEnter={() => setHoveredShop(shop.CODE)}
                  onMouseLeave={() => setHoveredShop(null)}
                  onClick={() => onFilter(shop)}
                >
                  <div className="flex items-center gap-3">
                    <div className="flex-1">
                      <div className="flex items-center gap-2">
                        <h3
                          className={
                            theme === "dark" ? "text-gray-200" : "text-gray-800"
                          }
                        >
                          {name}
                        </h3>
                        <span className="text-xs text-gray-500 dark:text-gray-400">
                          ({shop.CODE})
                        </span>
                      </div>
                      <div className="flex flex-col">
                        <div className="flex items-center text-sm text-gray-500 dark:text-gray-400">
                          <IoLocationSharp className="w-4 h-4 mr-1" />
                          <span>
                            {location.x}, {location.z}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      className={clsx(
                        "px-2 py-1 text-xs rounded-full flex items-center gap-1",
                        shop.ENABLED
                          ? "bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-100"
                          : "bg-red-100 text-red-800 dark:bg-red-800 dark:text-red-100",
                      )}
                    >
                      <BsCircleFill className="w-3 h-3" />
                      {shop.ENABLED ? "Actif" : "Inactif"}
                    </div>
                  </div>
                </div>
              </Card>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default ItemShopMap;
