import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Chip,
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Card,
  CardBody,
  Avatar,
  Progress,
} from "@heroui/react";
import { FaFlag, FaUserShield } from "react-icons/fa";
import { formatDate, formatTime, UserIsStaff } from "../../../utils/utils";
import { fetchUserDataFromAPI } from "../../../services/services";

const RowOptionsMenuTrigger = ({ userData, theme = "dark" }) => {
  const {
    USERNAME,
    UUID,
    LANG,
    ONLINE,
    GROUP,
    HOST_IP,
    FIRST_SEEN,
    LAST_SEEN,
    LAST_UPDATED,
    TIME_PLAYED,
  } = userData;

  const [isOpen, setOpen] = useState(false);
  const [modalData, setModalData] = useState(null);

  const flagLang = {
    FRENCH: "🇫🇷",
    ENGLISH: "🇬🇧",
    DEUTSCH: "🇩🇪",
  };

  const fetchDataAndOpenModal = async () => {
    try {
      const data = await fetchUserDataFromAPI(UUID);
      setModalData(data);
      setOpen(true);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchDataAndOpenModal();
    }
  }, [isOpen]);

  return (
    <section className="">
      <Button
        size="sm"
        variant="bordered"
        onPress={fetchDataAndOpenModal}
        className={`hover:bg-gray-100 transition-colors`}
      >
        <FaUserShield
          className={theme === "dark" ? "text-gray-500" : "text-gray-700"}
        />
      </Button>

      <Modal
        isOpen={isOpen}
        onOpenChange={setOpen}
        size="full"
        backdrop="blur"
        className={
          theme === "dark"
            ? "bg-gray-800 text-gray-200 overflow-y-scroll"
            : "bg-white text-gray-800 overflow-y-scroll"
        }
      >
        <ModalContent>
          <ModalHeader className="flex flex-col gap-1">
            Gestion de l'utilisateur
          </ModalHeader>
          {modalData && (
            <ModalBody>
              <div className="grid grid-cols-4 gap-6">
                {/* Profile Section */}
                <Card
                  className={
                    theme === "dark"
                      ? "bg-gray-700 text-gray-200"
                      : "bg-gray-100 text-gray-800"
                  }
                >
                  <CardBody className="flex flex-col items-center space-y-4">
                    <Avatar
                      src={`https://mc-heads.net/avatar/${UUID}`}
                      size="4xl"
                      className="mb-4 w-[300px] h-[300px] object-cover"
                    />
                    <div className="text-center">
                      <h2
                        className={
                          theme === "dark"
                            ? "text-2xl font-bold mb-2 text-gray-300"
                            : "text-2xl font-bold mb-2 text-gray-700"
                        }
                      >
                        {USERNAME}
                      </h2>
                      <div className="flex gap-2 justify-center mb-4">
                        <Chip color={ONLINE ? "success" : "danger"} size="md">
                          {ONLINE ? "Online" : "Offline"}
                        </Chip>
                        <Chip
                          startContent={<FaFlag />}
                          size="md"
                          variant="bordered"
                        >
                          {flagLang[LANG] || LANG}
                        </Chip>
                      </div>
                      <div>
                        <p
                          className={
                            theme === "dark" ? "text-gray-300" : "text-gray-700"
                          }
                        >
                          Grade: {GROUP}
                        </p>
                        <p
                          className={
                            theme === "dark" ? "text-gray-300" : "text-gray-700"
                          }
                        >
                          IP: {HOST_IP}
                        </p>
                      </div>
                    </div>
                  </CardBody>
                </Card>

                {/* User Stats */}
                <Card
                  className={
                    theme === "dark"
                      ? "bg-gray-700 text-gray-200"
                      : "bg-gray-100 text-gray-800"
                  }
                >
                  <CardBody className="space-y-6">
                    <div className="space-y-4">
                      <div className="flex items-center justify-between">
                        <span
                          className={
                            theme === "dark" ? "text-gray-300" : "text-gray-700"
                          }
                        >
                          Première connexion
                        </span>
                        <Chip size="sm">{formatDate(FIRST_SEEN)}</Chip>
                      </div>
                      <div className="flex items-center justify-between">
                        <span
                          className={
                            theme === "dark" ? "text-gray-300" : "text-gray-700"
                          }
                        >
                          Dernière connexion
                        </span>
                        <Chip size="sm">{formatDate(LAST_SEEN)}</Chip>
                      </div>
                      <div className="flex items-center justify-between">
                        <span
                          className={
                            theme === "dark" ? "text-gray-300" : "text-gray-700"
                          }
                        >
                          Dernière mise à jour
                        </span>
                        <Chip size="sm">{formatDate(LAST_UPDATED)}</Chip>
                      </div>
                      <div className="flex items-center justify-between">
                        <span
                          className={
                            theme === "dark" ? "text-gray-300" : "text-gray-700"
                          }
                        >
                          Temps de jeu
                        </span>
                        <Chip color="primary" size="sm">
                          {formatTime(TIME_PLAYED)}
                        </Chip>
                      </div>
                      <div className="flex items-center justify-between">
                        <span
                          className={
                            theme === "dark" ? "text-gray-300" : "text-gray-700"
                          }
                        >
                          Monnaie
                        </span>
                        <Chip color="secondary" size="sm">
                          {modalData.currency?.AMOUNT} coins
                        </Chip>
                      </div>
                    </div>
                  </CardBody>
                </Card>

                {/* Achievements Section */}
                <Card
                  className={
                    theme === "dark"
                      ? "bg-gray-700 text-gray-200 col-span-2"
                      : "bg-gray-100 text-gray-800 col-span-2"
                  }
                >
                  <CardBody>
                    <h3
                      className={
                        theme === "dark"
                          ? "text-lg font-semibold mb-4 text-gray-300"
                          : "text-lg font-semibold mb-4 text-gray-700"
                      }
                    >
                      Achievements
                    </h3>
                    {modalData?.achievements ? (
                      <>
                        <div className="flex items-center justify-between mb-4">
                          <span
                            className={
                              theme === "dark"
                                ? "text-gray-300"
                                : "text-gray-700"
                            }
                          >
                            Nombre d'achievements
                          </span>
                          <Chip color="primary">
                            {modalData.achievements.length} / 194
                          </Chip>
                        </div>
                        <Progress
                          value={(modalData.achievements.length / 194) * 100}
                          color="primary"
                          className="mb-4"
                        />
                        <div className="max-h-[300px] overflow-y-auto">
                          <Table
                            aria-label="Achievements"
                            className={
                              theme === "dark"
                                ? "bg-gray-700 text-gray-500"
                                : "bg-gray-100 text-gray-800"
                            }
                          >
                            <TableHeader>
                              <TableColumn
                                className={
                                  theme === "dark"
                                    ? "text-gray-500"
                                    : "text-gray-700"
                                }
                              >
                                CODE
                              </TableColumn>
                              <TableColumn
                                className={
                                  theme === "dark"
                                    ? "text-gray-500"
                                    : "text-gray-700"
                                }
                              >
                                DATE
                              </TableColumn>
                            </TableHeader>
                            <TableBody
                              emptyContent="Aucun achievement"
                              items={modalData.achievements}
                            >
                              {(item) => (
                                <TableRow key={item._id}>
                                  <TableCell
                                    className={
                                      theme === "dark"
                                        ? "text-gray-500"
                                        : "text-gray-700"
                                    }
                                  >
                                    {item.CODE}
                                  </TableCell>
                                  <TableCell
                                    className={
                                      theme === "dark"
                                        ? "text-gray-500"
                                        : "text-gray-700"
                                    }
                                  >
                                    {formatDate(item.REWARDED_AT)}
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </div>
                      </>
                    ) : (
                      <p
                        className={
                          theme === "dark" ? "text-gray-300" : "text-gray-700"
                        }
                      >
                        Chargement des achievements...
                      </p>
                    )}
                  </CardBody>
                </Card>

                {/* Sanctions Section */}
                <Card
                  className={
                    theme === "dark"
                      ? "bg-gray-700 text-gray-500 col-span-2"
                      : "bg-gray-100 text-gray-800 col-span-2"
                  }
                >
                  <CardBody>
                    <h3
                      className={
                        theme === "dark"
                          ? "text-lg font-semibold mb-4 text-gray-500"
                          : "text-lg font-semibold mb-4 text-gray-700"
                      }
                    >
                      Sanctions
                    </h3>
                    {modalData?.sanction ? (
                      <Table
                        aria-label="Sanctions"
                        className={
                          theme === "dark"
                            ? "bg-gray-700 text-gray-500"
                            : "bg-gray-100 text-gray-800"
                        }
                        className="max-h-[300px] overflow-y-auto"
                      >
                        <TableHeader>
                          <TableColumn
                            className={
                              theme === "dark"
                                ? "text-gray-500"
                                : "text-gray-700"
                            }
                          >
                            DATE
                          </TableColumn>
                          <TableColumn
                            className={
                              theme === "dark"
                                ? "text-gray-500"
                                : "text-gray-700"
                            }
                          >
                            PAR
                          </TableColumn>
                          <TableColumn
                            className={
                              theme === "dark"
                                ? "text-gray-500"
                                : "text-gray-700"
                            }
                          >
                            FIN
                          </TableColumn>
                          <TableColumn
                            className={
                              theme === "dark"
                                ? "text-gray-500"
                                : "text-gray-700"
                            }
                          >
                            RAISON
                          </TableColumn>
                          <TableColumn
                            className={
                              theme === "dark"
                                ? "text-gray-500"
                                : "text-gray-700"
                            }
                          >
                            TYPE
                          </TableColumn>
                        </TableHeader>
                        <TableBody
                          emptyContent="Aucune sanction"
                          items={modalData.sanction}
                        >
                          {(item) => (
                            <TableRow key={item._id}>
                              <TableCell
                                className={
                                  theme === "dark"
                                    ? "text-gray-300"
                                    : "text-gray-700"
                                }
                              >
                                {formatDate(item.AT)}
                              </TableCell>
                              <TableCell
                                className={
                                  theme === "dark"
                                    ? "text-gray-300"
                                    : "text-gray-700"
                                }
                              >
                                {UserIsStaff(item.BY)}
                              </TableCell>
                              <TableCell
                                className={
                                  theme === "dark"
                                    ? "text-gray-500"
                                    : "text-gray-700"
                                }
                              >
                                {formatDate(item.END)}
                              </TableCell>
                              <TableCell
                                className={
                                  theme === "dark"
                                    ? "text-gray-500"
                                    : "text-gray-700"
                                }
                              >
                                {item.REASON}
                              </TableCell>
                              <TableCell
                                className={
                                  theme === "dark"
                                    ? "text-gray-500"
                                    : "text-gray-700"
                                }
                              >
                                {item.TYPE}
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    ) : (
                      <p
                        className={
                          theme === "dark" ? "text-gray-500" : "text-gray-700"
                        }
                      >
                        Chargement des sanctions...
                      </p>
                    )}
                  </CardBody>
                </Card>
              </div>
            </ModalBody>
          )}

          <ModalFooter>
            <Button
              color="danger"
              variant="light"
              onPress={() => setOpen(false)}
            >
              Fermer
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </section>
  );
};

export default RowOptionsMenuTrigger;
