import React, { useCallback, useMemo, useState, useEffect } from "react";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Input,
  Button,
  DropdownTrigger,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Chip,
  User,
  Pagination,
  Spinner,
} from "@heroui/react";

import { getAllAccountsData } from "../../../services/services";
import {
  ChevronDownIcon,
  customSort,
  formatTime,
  SearchIcon,
} from "../../../utils/utils";
import RowOptionMenuTrigger from "./RowOptionMenuTrigger";
import FRENCH from "../../../assets/images/FRENCH.jpg";
import ENGLISH from "../../../assets/images/ENGLISH.jpg";
import DEUTSCH from "../../../assets/images/DEUTSCH.jpg";
import { FaMoon, FaSun } from "react-icons/fa6";
import { clsx } from "clsx";
import { FiChevronDown } from "react-icons/fi";

const flagLang = {
  FRENCH: FRENCH,
  ENGLISH: ENGLISH,
  DEUTSCH: DEUTSCH,
};

const columns = [
  { name: "SKIN", uid: "TEXTURE" },
  { name: "NAME", uid: "USERNAME" },
  { name: "GRADE", uid: "GROUP" },
  { name: "IP", uid: "HOST_IP" },
  { name: "LANG", uid: "LANG" },
  { name: "ONLINE", uid: "ONLINE" },
  { name: "TIME_PLAYED", uid: "TIME_PLAYED" },
  { name: "UUID", uid: "UUID" },
  { name: "ACTIONS", uid: "actions" },
];

const statusOptions = [
  { name: "Online", uid: "true" },
  { name: "Offline", uid: "false" },
];

const groupOptions = [
  { name: "Guest", uid: "GUEST" },
  { name: "Mage", uid: "MAGE" },
  { name: "Staff", uid: "STAFF" },
];

const statusColorMap = {
  true: "success",
  false: "danger",
};

const INITIAL_VISIBLE_COLUMNS = [
  "TEXTURE",
  "UUID",
  "HOST_IP",
  "ONLINE",
  "LANG",
  "TIME_PLAYED",
  "GROUP",
  "actions",
];

export default function Accounts() {
  const [theme, setTheme] = useState("dark");
  const [selectedKeys, setSelectedKeys] = useState(new Set([]));
  const [visibleColumns, setVisibleColumns] = useState(
    new Set(INITIAL_VISIBLE_COLUMNS),
  );
  const [statusFilter, setStatusFilter] = useState("all");
  const [filterValue, setFilterValue] = useState("");
  const [sortDescriptor, setSortDescriptor] = useState({
    column: "USERNAME",
    direction: "ascending",
  });
  const [items, setItems] = useState([20, 50, 75, 100]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const toggleTheme = useCallback(() => {
    setTheme((prevTheme) => (prevTheme === "dark" ? "light" : "dark"));
  }, [theme]);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await getAllAccountsData();
        setData(res.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  const filteredItems = useMemo(() => {
    let filteredUsers = [...data];

    if (filterValue) {
      filteredUsers = filteredUsers.filter(
        (user) =>
          user.USERNAME.toLowerCase().includes(filterValue.toLowerCase()) ||
          user.UUID.toLowerCase().includes(filterValue.toLowerCase()) ||
          user.HOST_IP.toLowerCase().includes(filterValue.toLowerCase()) ||
          user.GROUP.toLowerCase().includes(filterValue.toLowerCase()) ||
          user.LANG.toLowerCase().includes(filterValue.toLowerCase()),
      );
    }
    if (
      statusFilter !== "all" &&
      Array.from(statusFilter).length !== statusOptions.length
    ) {
      filteredUsers = filteredUsers.filter((user) =>
        Array.from(statusFilter).includes(user.ONLINE.toString()),
      );
    }

    return filteredUsers;
  }, [data, filterValue, statusFilter]);

  const sortedItems = useMemo(() => {
    return [...filteredItems].sort((a, b) => {
      const first = a[sortDescriptor.column];
      const second = b[sortDescriptor.column];

      const cmp = customSort(first, second);
      return sortDescriptor.direction === "descending" ? -cmp : cmp;
    });
  }, [sortDescriptor, filteredItems]);

  const paginatedItems = useMemo(() => {
    const start = (page - 1) * rowsPerPage;
    const end = start + rowsPerPage;
    return sortedItems.slice(start, end);
  }, [page, rowsPerPage, sortedItems]);

  const renderCell = (user, columnKey) => {
    const cellValue = user[columnKey];
    switch (columnKey) {
      case "TEXTURE":
        return (
          <User
            avatarProps={{
              src: `https://mc-heads.net/avatar/${user.UUID}`,
              size: "sm",
            }}
            name={user["USERNAME"]}
          />
        );
      case "LANG":
        return (
          <User
            avatarProps={{
              src: flagLang[user.LANG],
              size: "sm",
            }}
          />
        );
      case "ONLINE":
        return (
          <Chip
            className={clsx(
              "capitalize border-none",
              theme === "dark"
                ? "bg-gray-700 text-gray-300 hover:bg-gray-600"
                : "bg-gray-200 text-gray-700 hover:bg-gray-300",
            )}
            color={statusColorMap[cellValue.toString()]}
            size="sm"
            variant="dot"
          >
            {cellValue ? "Online" : "Offline"}
          </Chip>
        );
      case "GROUP":
        return Array.isArray(cellValue) ? cellValue.join(", ") : cellValue;
      case "TIME_PLAYED":
        return formatTime(cellValue);
      case "actions": {
        return (
          <div className="relative flex justify-center items-center">
            <RowOptionMenuTrigger userData={user} theme={theme} />
          </div>
        );
      }
      default:
        return cellValue;
    }
  };

  const onSearchChange = useCallback((value) => {
    if (value) {
      setFilterValue(value);
      setPage(1);
    } else {
      setFilterValue("");
    }
  }, []);

  const onRowsPerPageChange = useCallback((e) => {
    setRowsPerPage(Number(e.target.value));
    setPage(1);
  }, []);

  const onVisibleColumnsChange = useCallback((keys) => {
    setVisibleColumns(new Set(keys));
  }, []);

  const topContent = useMemo(() => {
    return (
      <div className="flex flex-col gap-4">
        <div className="flex justify-between gap-3 items-end">
          <Input
            isClearable
            classNames={{
              base: "w-full sm:max-w-[44%]",
              inputWrapper:
                theme === "dark"
                  ? "border-gray-700 text-white"
                  : "border-gray-300",
            }}
            placeholder="Rechercher ..."
            size="sm"
            startContent={
              <SearchIcon
                className={theme === "dark" ? "text-gray-500" : "text-gray-400"}
              />
            }
            value={filterValue}
            variant="bordered"
            onClear={() => setFilterValue("")}
            onValueChange={onSearchChange}
          />
          <div className="flex gap-3 items-center">
            <Dropdown>
              <DropdownTrigger className="hidden sm:flex">
                <Button
                  endContent={<FiChevronDown className="text-small" />}
                  variant="flat"
                  className={
                    theme === "dark"
                      ? "bg-gray-700 text-gray-300 hover:bg-gray-600"
                      : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                  }
                >
                  En ligne
                </Button>
              </DropdownTrigger>
              <DropdownMenu
                disallowEmptySelection
                aria-label="Table Columns"
                closeOnSelect={false}
                selectedKeys={statusFilter}
                selectionMode="multiple"
                onSelectionChange={setStatusFilter}
              >
                {statusOptions.map((status) => (
                  <DropdownItem key={status.uid} className="capitalize">
                    {status.name}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
            <Dropdown>
              <DropdownTrigger className="hidden sm:flex">
                <Button
                  endContent={<FiChevronDown className="text-small" />}
                  variant="flat"
                  className={
                    theme === "dark"
                      ? "bg-gray-700 text-gray-300 hover:bg-gray-600"
                      : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                  }
                >
                  Colonne active
                </Button>
              </DropdownTrigger>
              <DropdownMenu
                disallowEmptySelection
                aria-label="Table Columns"
                closeOnSelect={false}
                selectedKeys={visibleColumns}
                selectionMode="multiple"
                onSelectionChange={onVisibleColumnsChange}
              >
                {columns.map((column) => (
                  <DropdownItem key={column.uid} className="capitalize">
                    {column.name}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
            {/* Theme Toggle Button */}
            <Button
              isIconOnly
              variant="flat"
              onPress={toggleTheme}
              className={
                theme === "dark"
                  ? "bg-gray-700 text-gray-300 hover:bg-gray-600"
                  : "bg-gray-200 text-gray-700 hover:bg-gray-300"
              }
            >
              {theme === "dark" ? <FaSun size={20} /> : <FaMoon size={20} />}
            </Button>
          </div>
        </div>
        <div className="flex justify-between items-center">
          <span className="text-default-400 text-small">
            Total {filteredItems.length} Utilisateurs
          </span>
          <label className="flex items-center text-default-400 text-small">
            Résultat par page :
            <select
              className="bg-transparent outline-none text-default-400 text-small"
              onChange={onRowsPerPageChange}
            >
              {items.map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </label>
        </div>
      </div>
    );
  }, [
    filterValue,
    statusFilter,
    visibleColumns,
    onSearchChange,
    data.length,
    onRowsPerPageChange,
    onVisibleColumnsChange,
    toggleTheme,
    theme,
  ]);

  const bottomContent = useMemo(() => {
    return (
      <div className={`"py-2 px-2 flex justify-center items-center w-full"`}>
        <Pagination
          isCompact
          showControls
          showShadow
          color="primary"
          page={page}
          total={Math.ceil(sortedItems.length / rowsPerPage)}
          onChange={setPage}
        />
      </div>
    );
  }, [
    selectedKeys,
    data.length,
    page,
    sortedItems.length,
    rowsPerPage,
    toggleTheme,
    theme,
  ]);

  const classNames = useMemo(
    () => ({
      wrapper: [
        "max-h-[60vh]",
        theme === "dark"
          ? "bg-gray-900 text-gray-200"
          : "bg-white text-gray-800",
      ],
      th: [
        theme === "dark"
          ? "bg-gray-800 text-gray-300 border-gray-700"
          : "bg-gray-100 text-gray-700 border-gray-300",
        "border-b",
      ],
      td: [theme === "dark" ? "text-gray-400" : "text-gray-600"],
      tr: [
        "border-b",
        theme === "dark"
          ? "border-gray-800 hover:bg-gray-700"
          : "border-gray-300 hover:bg-gray-200",
      ],
    }),
    [theme],
  );

  return (
    <div
      className={`${theme === "dark" ? " bg-gray-900" : "bg-white"} p-6 rounded-lg shadow-lg overflow-x-hidden`}
    >
      {topContent}

      <Table
        aria-label=" table with custom cells, pagination and sorting"
        bottomContent={bottomContent}
        bottomContentPlacement="outside"
        classNames={classNames}
        sortDescriptor={sortDescriptor}
        topContentPlacement="outside"
        onSortChange={setSortDescriptor}
      >
        <TableHeader
          columns={columns.filter((column) => visibleColumns.has(column.uid))}
        >
          {(column) => (
            <TableColumn
              key={column.uid}
              align={column.uid === "actions" ? "center" : "start"}
              allowsSorting={column.sortable}
            >
              {column.name}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody
          emptyContent={"Aucun utilisateur trouvée !"}
          items={paginatedItems}
          loadingContent={<Spinner />}
          loadingState={loading ? "loading" : "idle"}
        >
          {(item) => (
            <TableRow key={item.UUID}>
              {(columnKey) => (
                <TableCell>{renderCell(item, columnKey)}</TableCell>
              )}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
}
