import React, { useCallback, useMemo, useState, useEffect } from "react";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Input,
  Button,
  DropdownTrigger,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Chip,
  Pagination,
  Spinner,
  useDisclosure,
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
} from "@heroui/react";
import {
  FiSearch,
  FiChevronDown,
  FiMoreVertical,
  FiPlus,
  FiFilter,
} from "react-icons/fi";
import { FaMoon, FaSun } from "react-icons/fa6";
import {
  deleteAchievementsListData,
  getAchievementsListData,
  setAchievementsListData,
} from "../../../services/services";
import AchievementFilters from "./AchievementFilters";
import AddAchievementForm from "./AddAchievementForm";

import { formatDate, formatTime, SearchIcon } from "../../../utils/utils";
import { toast } from "react-toastify";
import getMessageMapping from "../../../utils/Message";
import { FaTrash } from "react-icons/fa";

const columns = [
  { name: "UUID", uid: "_id" },
  { name: "CODE", uid: "CODE", sortable: true },
  { name: "NOM", uid: "NAME", sortable: true },
  { name: "CATEGORIE", uid: "CATEGORY", sortable: true },
  { name: "DESCRIPTION", uid: "DESCRIPTION" },
  { name: "TYPE", uid: "TYPE" },
  { name: "REGION", uid: "REGION" },
  { name: "VISIBLE", uid: "VISIBLE", sortable: true },
  { name: "CREATED_AT", uid: "CREATED_AT", sortable: true },
  { name: "ACTIONS", uid: "ACTIONS" },
];
// <FaTrash />
const statusOptions = [
  { name: "Visible", uid: "true" },
  { name: "Hidden", uid: "false" },
];

const INITIAL_VISIBLE_COLUMNS = [
  "CODE",
  "NAME",
  "CATEGORY",
  "DESCRIPTION",
  "TYPE",
  "REGION",
  "VISIBLE",
  "CREATED_AT",
  "ACTIONS",
];

export default function AchievementsPage() {
  const [achievements, setAchievements] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState(new Set([]));
  const [visibleColumns, setVisibleColumns] = useState(
    new Set(INITIAL_VISIBLE_COLUMNS),
  );
  const [statusFilter, setStatusFilter] = useState("all");
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [rowsArrays, setRowsArrays] = useState([25, 50, 75]);
  const [sortDescriptor, setSortDescriptor] = useState({
    column: "NAME",
    direction: "ascending",
  });
  const [page, setPage] = useState(1);
  const [filterValue, setFilterValue] = useState("");
  const [loading, setLoading] = useState(true);
  const [categoryFilter, setCategoryFilter] = useState("");
  const [regionFilter, setRegionFilter] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [theme, setTheme] = useState("dark");
  const [deleteDrawer, setDeleteDrawer] = useState({
    isOpen: false,
    achievement: null,
    confirmCode: "",
    error: "",
  });

  const {
    isOpen: isAddDrawerOpen,
    onOpen: onAddDrawerOpen,
    onClose: onAddDrawerClose,
  } = useDisclosure();

  useEffect(() => {
    fetchAchievements();
  }, []);

  const fetchAchievements = async () => {
    setLoading(true);
    try {
      const response = await getAchievementsListData();
      setAchievements(response.data.data);
    } catch (error) {
      console.error("Error fetching achievements:", error);
    } finally {
      setLoading(false);
    }
  };

  const categories = [...new Set(achievements.map((a) => a.CATEGORY))];
  const types = [...new Set(achievements.map((a) => a.TYPE))];

  const hasSearchFilter = Boolean(filterValue);

  const headerColumns = useMemo(() => {
    return columns.filter((column) =>
      Array.from(visibleColumns).includes(column.uid),
    );
  }, [visibleColumns]);

  const filteredItems = useMemo(() => {
    let filteredAchievements = [...achievements];

    if (hasSearchFilter) {
      filteredAchievements = filteredAchievements.filter(
        (achievement) =>
          achievement.NAME.toLowerCase().includes(filterValue.toLowerCase()) ||
          achievement.REGION.toLowerCase().includes(
            filterValue.toLowerCase(),
          ) ||
          achievement.CATEGORY.toLowerCase().includes(
            filterValue.toLowerCase(),
          ) ||
          achievement.TYPE.toLowerCase().includes(filterValue.toLowerCase()) ||
          achievement.CODE.toLowerCase().includes(filterValue.toLowerCase()),
      );
    }
    if (
      statusFilter !== "all" &&
      Array.from(statusFilter).length !== statusOptions.length
    ) {
      filteredAchievements = filteredAchievements.filter((achievement) =>
        Array.from(statusFilter).includes(achievement.VISIBLE.toString()),
      );
    }
    if (categoryFilter && categoryFilter !== "All Categories") {
      filteredAchievements = filteredAchievements.filter(
        (achievement) => achievement.CATEGORY === categoryFilter,
      );
    }
    if (regionFilter && regionFilter !== "All Regions") {
      filteredAchievements = filteredAchievements.filter(
        (achievement) => achievement.REGION === regionFilter,
      );
    }

    return filteredAchievements;
  }, [
    achievements,
    filterValue,
    statusFilter,
    hasSearchFilter,
    categoryFilter,
    regionFilter,
  ]);

  const pages = Math.ceil(filteredItems.length / rowsPerPage);

  const items = useMemo(() => {
    const start = (page - 1) * rowsPerPage;
    const end = start + rowsPerPage;

    return filteredItems.slice(start, end);
  }, [page, filteredItems, rowsPerPage]);

  const sortedItems = useMemo(() => {
    return [...items].sort((a, b) => {
      const first = a[sortDescriptor.column];
      const second = b[sortDescriptor.column];
      const cmp = first < second ? -1 : first > second ? 1 : 0;

      return sortDescriptor.direction === "descending" ? -cmp : cmp;
    });
  }, [sortDescriptor, items]);

  const renderCell = useCallback((achievement, columnKey) => {
    const cellValue = achievement[columnKey];

    switch (columnKey) {
      case "VISIBLE":
        return (
          <Chip
            className="capitalize"
            color={achievement.VISIBLE ? "success" : "danger"}
            size="sm"
            variant="flat"
          >
            {achievement.VISIBLE ? "Visible" : "Hidden"}
          </Chip>
        );

      case "CREATED_AT":
        return formatDate(cellValue);

      case "ACTIONS":
        return (
          <Button
            isIconOnly
            aria-label="supprimer"
            variant="flat"
            color="danger"
            onPress={() => handleDelete(achievement)}
            className=" flex justify-center items-center"
          >
            <FaTrash />
          </Button>
        );
      default:
        return cellValue;
    }
  }, []);

  const onNextPage = useCallback(() => {
    if (page < pages) {
      setPage(page + 1);
    }
  }, [page, pages]);

  const onPreviousPage = useCallback(() => {
    if (page > 1) {
      setPage(page - 1);
    }
  }, [page]);

  const onRowsPerPageChange = useCallback((e) => {
    setRowsPerPage(Number(e.target.value));
    setPage(1);
  }, []);

  const onSearchChange = useCallback((value) => {
    if (value) {
      setFilterValue(value);
      setPage(1);
    } else {
      setFilterValue("");
    }
  }, []);

  const onClear = useCallback(() => {
    setFilterValue("");
    setPage(1);
  }, []);

  const onAddAchievement = async (newAchievement) => {
    try {
      const { data, response } = await setAchievementsListData(newAchievement);

      if (response.status === 201) {
        await fetchAchievements();
        sessionStorage.setItem(
          "newAchievement",
          JSON.stringify(newAchievement, null, 2),
        );
        toast.success(getMessageMapping("ACHIEVEMENTS_ADD"));
        onAddDrawerClose();
      }
    } catch (e) {
      toast.error(getMessageMapping("ACHIEVEMENTS_NOT_ADD"));
      console.error(e);
    }
  };

  const openDeleteDrawer = (achievement) => {
    setDeleteDrawer({
      isOpen: true,
      achievement,
      confirmCode: "",
      error: "",
    });
  };

  const closeDeleteDrawer = () => {
    setDeleteDrawer({
      isOpen: false,
      achievement: null,
      confirmCode: "",
      error: "",
    });
  };

  const handleDeleteConfirm = async () => {
    try {
      const { data, response } = await deleteAchievementsListData(
        deleteDrawer.achievement._id,
      );

      if (response.status === 200) {
        await fetchAchievements();
        toast.success("Achievement supprimé avec succès");
        closeDeleteDrawer();
      }
    } catch (error) {
      console.error(error);
      toast.error("Erreur lors de la suppression");
    }
  };

  const handleDelete = (achievement) => {
    openDeleteDrawer(achievement);
  };

  const onFilter = ({ category, region }) => {
    setCategoryFilter(category);
    setRegionFilter(region);
  };

  const toggleTheme = useCallback(() => {
    setTheme((prevTheme) => (prevTheme === "dark" ? "light" : "dark"));
  }, []);

  const topContent = useMemo(() => {
    return (
      <div className="flex flex-col gap-4">
        <div className="flex justify-between gap-3 items-end">
          <Input
            isClearable
            classNames={{
              base: "w-full sm:max-w-[44%]",
              inputWrapper:
                theme === "dark"
                  ? "border-gray-700 text-white"
                  : "border-gray-300",
            }}
            placeholder="Rechercher ..."
            size="sm"
            startContent={
              <SearchIcon
                className={theme === "dark" ? "text-gray-500" : "text-gray-400"}
              />
            }
            value={filterValue}
            variant="bordered"
            onClear={() => setFilterValue("")}
            onValueChange={onSearchChange}
          />

          <div className="flex gap-3">
            <Button
              endContent={<FiFilter />}
              variant="flat"
              onClick={() => setShowFilter(!showFilter)}
              className={
                theme === "dark"
                  ? "bg-gray-700 text-gray-300 hover:bg-gray-600"
                  : "bg-gray-200 text-gray-700 hover:bg-gray-300"
              }
            >
              Filtre
            </Button>

            <Button
              color="primary"
              endContent={<FiPlus />}
              onClick={onAddDrawerOpen}
            >
              Ajouter
            </Button>
            <Dropdown key="columns-filter">
              <DropdownTrigger className="hidden sm:flex">
                <Button
                  endContent={<FiChevronDown className="text-small" />}
                  variant="flat"
                  className={
                    theme === "dark"
                      ? "bg-gray-700 text-gray-300 hover:bg-gray-600"
                      : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                  }
                >
                  Colonne active
                </Button>
              </DropdownTrigger>
              <DropdownMenu
                disallowEmptySelection
                aria-label="Table Columns"
                closeOnSelect={false}
                selectedKeys={visibleColumns}
                selectionMode="multiple"
                onSelectionChange={setVisibleColumns}
              >
                {columns.map((column) => (
                  <DropdownItem key={column.uid} className="capitalize">
                    {column.name}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
            <Button
              isIconOnly
              variant="flat"
              onClick={toggleTheme}
              className={
                theme === "dark"
                  ? "bg-gray-700 text-gray-300 hover:bg-gray-600"
                  : "bg-gray-200 text-gray-700 hover:bg-gray-300"
              }
            >
              {theme === "dark" ? <FaSun size={20} /> : <FaMoon size={20} />}
            </Button>
          </div>
        </div>
        {showFilter && (
          <div className="flex justify-between gap-3">
            <AchievementFilters
              achievements={achievements}
              onFilter={onFilter}
              initialCategory={categoryFilter}
              initialRegion={regionFilter}
            />
          </div>
        )}
        <div className="flex justify-between items-center">
          <span className="text-default-400 text-small">
            Total {achievements.length} achievements
          </span>
          <label className="flex items-center text-default-400 text-small">
            Résultat par page:
            <select
              className="pl-2 bg-transparent outline-none text-default-400 text-small cursor-pointer"
              onChange={onRowsPerPageChange}
            >
              {rowsArrays.map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </label>
        </div>
      </div>
    );
  }, [
    achievements.length,
    filterValue,
    visibleColumns,
    theme,
    onSearchChange,
    onClear,
    onRowsPerPageChange,
    onAddDrawerOpen,
    toggleTheme,
    showFilter,
  ]);

  const bottomContent = useMemo(() => {
    return (
      <div className="py-2 px-2 flex justify-center items-center">
        <Pagination
          isCompact
          showControls
          showShadow
          color="primary"
          page={page}
          total={pages}
          onChange={setPage}
        />
      </div>
    );
  }, [
    selectedKeys,
    filteredItems.length,
    page,
    pages,
    onPreviousPage,
    onNextPage,
  ]);

  return (
    <div
      className={`space-y-4 ${theme === "dark" ? "bg-gray-900" : "bg-white"} p-8 transition-colors duration-300`}
    >
      <Table
        aria-label="Achievements table with custom cells, pagination and sorting"
        bottomContent={bottomContent}
        bottomContentPlacement="outside"
        classNames={{
          wrapper: ` max-h-[54vh] ${theme === "dark" ? "bg-gray-900 text-gray-200" : "bg-white text-gray-800"}`,
          th:
            theme === "dark"
              ? "bg-gray-800 text-gray-300 border-b border-gray-700"
              : "bg-gray-100 text-gray-700 border-b border-gray-300",
          td: theme === "dark" ? "text-gray-400" : "text-gray-600",
          tr:
            theme === "dark"
              ? "border-b border-gray-800 hover:bg-gray-700"
              : "border-b border-gray-300 hover:bg-gray-200",
        }}
        sortDescriptor={sortDescriptor}
        topContent={topContent}
        topContentPlacement="outside"
        onSortChange={setSortDescriptor}
      >
        <TableHeader columns={headerColumns}>
          {(column) => (
            <TableColumn
              key={column.uid}
              align={column.uid === "actions" ? "center" : "start"}
              allowsSorting={column.sortable}
            >
              {column.name}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody
          emptyContent={"pas achievements trouvée!"}
          items={sortedItems}
          loadingContent={<Spinner />}
          loadingState={loading ? "chargement" : "idle"}
        >
          {(item) => (
            <TableRow key={item.ID || item.CODE}>
              {(columnKey) => (
                <TableCell>{renderCell(item, columnKey)}</TableCell>
              )}
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Drawer
        isOpen={isAddDrawerOpen}
        onClose={onAddDrawerClose}
        placement="left"
        size="5xl"
        backdrop={"blur"}
      >
        <DrawerContent
          className={`${theme === "dark" ? "bg-gray-800" : "bg-white"}`}
        >
          <DrawerHeader className="flex flex-col gap-1">
            <h3
              className={theme === "dark" ? "text-gray-300" : "text-gray-700"}
            >
              {" "}
              Ajouter un nouvelle achievement
            </h3>
          </DrawerHeader>
          <DrawerBody>
            <AddAchievementForm
              onSubmit={onAddAchievement}
              onCancel={onAddDrawerClose}
              theme={theme}
              categories={categories}
              types={types}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      {/* Drawer de confirmation de suppression */}
      <Drawer
        isOpen={deleteDrawer.isOpen}
        onClose={closeDeleteDrawer}
        placement="right"
        size="xl"
        backdrop={"blur"}
      >
        <DrawerContent
          className={theme === "dark" ? "bg-gray-800" : "bg-white"}
        >
          <DrawerHeader className="flex flex-col gap-1">
            <h3
              className={theme === "dark" ? "text-gray-300" : "text-gray-700"}
            >
              Confirmer la suppression
            </h3>
          </DrawerHeader>

          <DrawerBody>
            <div className="flex flex-col gap-4">
              <div
                className={`p-4 rounded-lg ${theme === "dark" ? "bg-gray-700" : "bg-gray-100"}`}
              >
                <h4
                  className={`text-lg mb-2 ${theme === "dark" ? "text-gray-300" : "text-gray-700"}`}
                >
                  Informations de l'achievement
                </h4>
                <div className="space-y-2">
                  <p
                    className={
                      theme === "dark" ? "text-gray-400" : "text-gray-600 "
                    }
                  >
                    <span className="">Code:</span>{" "}
                    <span
                      className={
                        "px-3 py-2 bg-[#3f1632] rounded-md text-[#f31260]"
                      }
                    >
                      {deleteDrawer.achievement?.CODE}
                    </span>
                  </p>
                  <p
                    className={
                      theme === "dark" ? "text-gray-400" : "text-gray-600"
                    }
                  >
                    <span className="font-semibold">Nom:</span>{" "}
                    {deleteDrawer.achievement?.NAME}
                  </p>
                  <p
                    className={
                      theme === "dark" ? "text-gray-400" : "text-gray-600"
                    }
                  >
                    <span className="font-semibold">Catégorie:</span>{" "}
                    {deleteDrawer.achievement?.CATEGORY}
                  </p>
                </div>
              </div>

              <div className="space-y-2">
                <p
                  className={
                    theme === "dark" ? "text-gray-300" : "text-gray-700"
                  }
                >
                  Pour confirmer la suppression, veuillez saisir le code de
                  l'achievement
                </p>
                <Input
                  label="Code de confirmation"
                  placeholder="Entrez le code"
                  value={deleteDrawer.confirmCode.toUpperCase()}
                  onChange={(e) =>
                    setDeleteDrawer((prev) => ({
                      ...prev,
                      confirmCode: e.target.value,
                      error: "",
                    }))
                  }
                  color={deleteDrawer.error ? "danger" : "default"}
                  errorMessage={deleteDrawer.error}
                  className={theme === "dark" ? "text-white" : "text-gray-800"}
                />
              </div>
            </div>
          </DrawerBody>

          <DrawerFooter>
            <div className="flex justify-end gap-3">
              <Button
                variant="flat"
                onPress={closeDeleteDrawer}
                className={
                  theme === "dark"
                    ? "bg-gray-700 text-gray-300"
                    : "bg-gray-200 text-gray-700"
                }
              >
                Annuler
              </Button>
              <Button
                color="danger"
                onPress={handleDeleteConfirm}
                className={
                  theme === "dark"
                    ? "bg-red-600 text-white"
                    : "bg-red-500 text-white"
                }
              >
                Supprimer
              </Button>
            </div>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </div>
  );
}
