import { Route, Routes } from "react-router-dom";
import RouteGuard from "./Guard/RouteGuard";
import { isAuthenticate } from "./services/authContext";

import Error from "./pages/Error/Error.jsx";
import SignIn from "./pages/SignIn/SignIn.jsx";
import ConnectedLayout from "./components/Layout/ConnectedLayout.jsx";
import Info from "./pages/Info/Info.jsx";
import Dashboard from "./pages/Acounts/DashBoard";
import WebDashboard from "./pages/Web/WebDashboard";

function App() {
  return (
    <Routes>
      {/*Accès connection*/}
      <Route path="/" element={<SignIn />} />
      {/*Accès compte*/}
      <Route
        path="/account"
        element={
          <RouteGuard checkFn={isAuthenticate}>
            <ConnectedLayout />
          </RouteGuard>
        }
      >
        <Route path="/account" element={<Dashboard />} />
      </Route>

      <Route
        path="/web"
        element={
          <RouteGuard checkFn={isAuthenticate}>
            <ConnectedLayout />
          </RouteGuard>
        }
      >
        <Route path="/web" element={<WebDashboard />} />
      </Route>
      {/*<Route path='/account/info/' element={<Info/>}/>*/}
      {/*Accès Error*/}
      <Route path="*" element={<Error />} />
    </Routes>
  );
}

export default App;
