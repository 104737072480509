import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Button, Input, Textarea, Select, SelectItem } from "@heroui/react";

const validationSchema = Yup.object().shape({
  code: Yup.string().required("Requis"),
  name: Yup.string().required("Requis"),
  description: Yup.string().required("Requis"),
  category: Yup.string().required("Requis"),
  type: Yup.string().required("Requis"),
  visible: Yup.boolean().required("Requis"),
});

const AddAchievementForm = ({
  onSubmit,
  onCancel,
  theme,
  categories,
  types,
}) => {
  const initialValues = {
    code: "",
    name: "",
    description: "",
    category: "",
    type: "",
    region: "",
    visible: true,
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        const transformedValues = Object.keys(values).reduce((acc, key) => {
          acc[key.toUpperCase()] = values[key];
          return acc;
        }, {});

        transformedValues.ID = Date.now().toString();
        onSubmit(transformedValues);
        setSubmitting(false);
      }}
    >
      {({ errors, touched, isSubmitting, values, setFieldValue }) => (
        <Form
          className={`space-y-4 p-6 ${theme === "dark" ? "bg-gray-800" : "bg-white"}`}
        >
          <div className="flex flex-col gap-5">
            {/* Code field */}
            <Field name="code">
              {({ field }) => (
                <Input
                  {...field}
                  label="Code"
                  placeholder="Achievement code"
                  className={theme === "dark" ? "text-white" : "text-gray-800"}
                />
              )}
            </Field>
            {errors.code && touched.code && (
              <div className="text-red-500">{errors.code}</div>
            )}

            {/* Name field */}
            <Field name="name">
              {({ field }) => (
                <Input
                  {...field}
                  label="Nom"
                  placeholder="Nom achievement"
                  className={theme === "dark" ? "text-white" : "text-gray-800"}
                />
              )}
            </Field>
            {errors.name && touched.name && (
              <div className="text-red-500">{errors.name}</div>
            )}

            {/* Description field */}
            <Field name="description">
              {({ field }) => (
                <Textarea
                  {...field}
                  label="Description"
                  placeholder="Saisir la description"
                  className={theme === "dark" ? "text-white" : "text-gray-800"}
                />
              )}
            </Field>
            {errors.description && touched.description && (
              <div className="text-red-500">{errors.description}</div>
            )}

            {/* Category field */}
            <div>
              <Select
                label="Categorie"
                placeholder="Categorie"
                value={values.category}
                onChange={(e) => {
                  const selectedValue = e.target.value || "GENERAL";
                  setFieldValue("category", selectedValue);
                }}
                className={theme === "dark" ? "text-white" : "text-gray-800"}
              >
                {categories.map((category) => (
                  <SelectItem key={category} value={category}>
                    {category}
                  </SelectItem>
                ))}
              </Select>
              {errors.category && touched.category && (
                <div className="text-red-500">{errors.category}</div>
              )}
            </div>

            {/* Type field */}
            <div>
              <Select
                value={values.type}
                onChange={(e) => {
                  const selectedValue = e.target.value || "DISCOVER";
                  setFieldValue("type", selectedValue);
                }}
                className={theme === "dark" ? "text-white" : "text-gray-800"}
                label="Type"
                placeholder="Type"
              >
                {types.map((type) => (
                  <SelectItem key={type} value={type}>
                    {type}
                  </SelectItem>
                ))}
              </Select>
              {errors.type && touched.type && (
                <div className="text-red-500">{errors.type}</div>
              )}
            </div>

            {/* Region field */}
            <Field name="region">
              {({ field }) => (
                <Input
                  {...field}
                  label="Region"
                  placeholder="Region"
                  className={theme === "dark" ? "text-white" : "text-gray-800"}
                />
              )}
            </Field>
            {errors.region && touched.region && (
              <div className="text-red-500">{errors.region}</div>
            )}

            {/* Visible field */}
            <div>
              <Select
                label="Visible"
                placeholder="Visible"
                value={values.visible}
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  setFieldValue("visible", selectedValue);
                }}
                className={theme === "dark" ? "text-white" : "text-gray-800"}
              >
                <SelectItem key={"true"}>Visible</SelectItem>
                <SelectItem key={"false"}>Cacher</SelectItem>
              </Select>
            </div>
          </div>

          {/*<pre className="text-sm bg-gray-100 p-4 rounded">*/}
          {/*  {JSON.stringify(values, null, 2)}*/}
          {/*</pre>*/}
          <div></div>

          <div className="absolute bottom-5 right-5 flex justify-end space-x-2 mt-6 text-white">
            <Button
              auto
              flat
              color="error"
              onClick={onCancel}
              className={
                theme === "dark"
                  ? "bg-red-600 hover:bg-red-700"
                  : "bg-red-500 hover:bg-red-600"
              }
            >
              Annuler
            </Button>
            <Button
              auto
              type="submit"
              disabled={isSubmitting}
              className={
                theme === "dark"
                  ? "bg-blue-600 hover:bg-blue-700 text-white"
                  : "bg-blue-500 hover:bg-blue-600 text-white"
              }
            >
              Envoyer
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddAchievementForm;
